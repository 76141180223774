import { useContext, useRef, useState } from 'react';
import { Stack, Button, Grid, Popover } from '@mui/material';
import { Color } from '../../../../Color';
import TotalsChart from './charts/TotalsChart';
import TopMediaChart from './charts/TopMediaChart';
// import LocationChart from './charts/LocationChart';
// import CategoriesChart from './charts/CategoriesChart';
import ClickChart from './charts/ClickChart';
// import ScanLog from './charts/ScanLog';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateTime } from 'luxon';
import { DateRange, TimePicker } from '@mui/x-date-pickers-pro';
import { Metric, Source, Timeframe, TimeframeAndInterval, timeframeAndIntervals } from 'src/types/Analytics';
import UserContext, { TUser } from 'src/contexts/UserContext';
import ActivityChart from './charts/ActivityChart';
// import CategoriesChart from './charts/CategoriesChart';

interface AnalyticsTopProps {
    campaignID: number;
    campaignCreated: number | undefined;
}

export function AnalyticsTop({ campaignID, campaignCreated }: AnalyticsTopProps) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;

    const [source, setSource] = useState<Source>(Source.IRDB);
    const now = DateTime.now();

    // const [currentTimeframeAndInterval, setCurrentTimeframeAndInterval] = useState<TimeframeAndInterval | undefined>(timeframeAndIntervals[3]);
    // const [range, setRange] = useState<DateRange<DateTime>>([now.minus({ days: 7 }).startOf('day'), now.endOf('day')]);
    // const [useCustom, setUseCustom] = useState(false);

    const [currentTimeframeAndInterval, setCurrentTimeframeAndInterval] = useState<TimeframeAndInterval | undefined>();
    const [range, setRange] = useState<DateRange<DateTime>>([
        DateTime.fromMillis(1731542400 * 1000),
        DateTime.fromMillis(1731553200 * 1000),
    ]);
    const [useCustom, setUseCustom] = useState(true);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [metric, setMetric] = useState<Metric>(Metric.Scans);

    const campaignCreatedTimestamp = (campaignCreated || 0) * 1000;
    const diffInDays = Math.ceil((now.toMillis() - campaignCreatedTimestamp) / (1000 * 60 * 60 * 24));

    const start = range[0]?.toISO({ includeOffset: true }) ?? undefined;
    const end = range[1]?.toISO({ includeOffset: true }) ?? undefined;

    const isDisabledTimeframe = (value: Timeframe) => {
        if (!campaignCreated) return false;
        switch (value) {
            case Timeframe.This30Days:
                return diffInDays < 7;
            case Timeframe.This90Days:
                return diffInDays < 30;
            case Timeframe.This180Days:
                return diffInDays < 90;
            case Timeframe.ThisYear:
                return diffInDays < 180;
            default:
                return false;
        }
    };

    const timeOptionsRef = useRef<HTMLDivElement>(null!);

    return (
        <>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 3,
                }}
                ref={timeOptionsRef}
            >
                {/* <Stack direction="row" spacing={1}>
                    {[Source.Keen, Source.IRDB].map(item => {
                        return (
                            <Button
                                key={item}
                                sx={{
                                    backgroundColor:
                                        source === item ? Color.PrimaryLavender : Color.LightLavenderLightMode,
                                    color:
                                        source === item ? Color.White
                                        : darkMode ? Color.White
                                        : Color.Black,
                                    borderRadius: 1,
                                }}
                                onClick={() => {
                                    setSource(item);
                                }}
                            >
                                {item}
                            </Button>
                        );
                    })}
                </Stack> */}
                <Stack direction="row">
                    <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                        {timeframeAndIntervals.map(item => {
                            return (
                                <Button
                                    key={item.label}
                                    disabled={isDisabledTimeframe(item.timeframe)}
                                    sx={{
                                        marginRight: 1,
                                        marginBottom: 1,
                                        backgroundColor:
                                            currentTimeframeAndInterval === item ?
                                                Color.PrimaryLavender
                                            :   Color.LightLavenderLightMode,
                                        color:
                                            currentTimeframeAndInterval === item ? Color.White
                                            : darkMode ? Color.White
                                            : Color.Black,
                                        borderRadius: 1,
                                    }}
                                    onClick={() => {
                                        setUseCustom(false);
                                        setCurrentTimeframeAndInterval(item);
                                    }}
                                >
                                    {item.label}
                                </Button>
                            );
                        })}

                        <Button
                            key="Custom"
                            sx={{
                                marginRight: 1,
                                marginBottom: 1,
                                backgroundColor: useCustom ? Color.PrimaryLavender : Color.LightLavenderLightMode,
                                color:
                                    useCustom ? Color.White
                                    : darkMode ? Color.White
                                    : Color.Black,
                                borderRadius: 1,
                            }}
                            onClick={event => {
                                setAnchorEl(event.currentTarget);
                                setCurrentTimeframeAndInterval(undefined);
                                setUseCustom(true);
                            }}
                        >
                            Custom
                        </Button>
                    </Stack>
                </Stack>

                <TotalsChart
                    source={source}
                    campaignID={campaignID}
                    // metric={metric}
                    metric={Metric.Scans}
                    timeframeAndInterval={currentTimeframeAndInterval}
                    // start={range[0]?.toISO({ includeOffset: false }) ?? undefined}
                    // end={range[1]?.toISO({ includeOffset: false }) ?? undefined}
                    start={start}
                    end={end}
                />

                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <ActivityChart
                            source={source}
                            campaignID={campaignID}
                            // metric={metric}
                            timeframeAndInterval={currentTimeframeAndInterval}
                            // start={range[0]?.toISO({ includeOffset: true }) ?? undefined}
                            // end={range[1]?.toISO({ includeOffset: true }) ?? undefined}
                            start={start}
                            end={end}
                            // start: start ? (DateTime.fromISO(start).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
                            // end: end ? (DateTime.fromISO(end).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),

                            // start={start ? (DateTime.fromISO(start).toISO() ?? undefined) : undefined} // .toFormat({ zone: 'America/Chicago' }),

                            // onMetricChange={setMetric}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <TopMediaChart
                            source={source}
                            campaignID={campaignID}
                            metric={metric}
                            timeframeAndInterval={currentTimeframeAndInterval}
                            // start={range[0]?.toISO({ includeOffset: false }) ?? undefined}
                            // end={range[1]?.toISO({ includeOffset: false }) ?? undefined}
                            start={start}
                            end={end}
                            onMetricChange={setMetric}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    gap={2}
                    sx={{
                        flexWrap: { md: 'nowrap' },
                    }}
                >
                    {/* <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <LocationChart
                            source={source}
                            campaignID={campaignID}
                            metric={metric}
                            timeframeAndInterval={currentTimeframeAndInterval}
                            start={start}
                            end={end}
                        />
                    </Grid> */}
                    <Grid item container xs={12} /* md={8} */ gap={2}>
                        {/* <Grid
                            item
                            xs={12}
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                            }}
                        >
                            <ViewsHistoryChart
                                source={source}
                                campaignID={campaignID}
                                metric={metric}
                                timeframeAndInterval={currentTimeframeAndInterval}
                                start={range[0]?.toISO({ includeOffset: false }) ?? undefined}
                                end={range[1]?.toISO({ includeOffset: false }) ?? undefined}
                            />
                        </Grid> */}
                        {/* <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                            }}
                        >
                            <CategoriesChart
                                source={source}
                                campaignID={campaignID}
                                timeframe={currentTimeframeAndInterval?.timeframe}
                            />
                        </Grid> */}
                        <Grid
                            item
                            xs={12}
                            md
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                            }}
                        >
                            <ClickChart
                                source={source}
                                campaignID={campaignID}
                                timeframe={currentTimeframeAndInterval?.timeframe}
                                // start={range[0]?.toISO({ includeOffset: false }) ?? undefined}
                                // end={range[1]?.toISO({ includeOffset: false }) ?? undefined}
                                start={start}
                                end={end}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* <ScanLog
                    source={source}
                    campaignID={campaignID}
                    timeframe={currentTimeframeAndInterval?.timeframe}
                    timeOptionsRef={timeOptionsRef}
                /> */}
            </Stack>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setAnchorEl(null);
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DateRangeCalendar
                        calendars={2}
                        value={range}
                        onChange={newValue => {
                            // console.log('newValue', JSON.stringify(newValue));
                            newValue[0] = newValue[0].startOf('day');
                            newValue[1] = newValue[1].endOf('day');
                            // console.log('newValue', JSON.stringify(newValue));
                            setRange(newValue);
                        }}
                    />

                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            p: 2,
                            justifyContent: 'space-between',
                        }}
                    >
                        <TimePicker
                            label="Start Time"
                            value={range[0]}
                            onChange={newValue => {
                                if (!newValue) return;
                                const newStartDateTime = range[0]!.set({
                                    hour: newValue.hour,
                                    minute: newValue.minute,
                                    second: newValue.second,
                                    millisecond: newValue.millisecond,
                                });
                                setRange([newStartDateTime, range[1]]);
                            }}
                            // renderInput={params => <TextField {...params} />}
                        />
                        <TimePicker
                            label="End Time"
                            value={range[1]}
                            onChange={newValue => {
                                if (!newValue) return;
                                const newEndDateTime = range[1]!.set({
                                    hour: newValue.hour,
                                    minute: newValue.minute,
                                    second: newValue.second,
                                    millisecond: newValue.millisecond,
                                });
                                setRange([range[0], newEndDateTime]);
                            }}
                            // onChange={newValue => setEndTime(newValue)}
                            // renderInput={params => <TextField {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
                <Stack
                    direction="row"
                    sx={{
                        paddingBottom: 2,
                        paddingRight: 2,
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            setAnchorEl(null);
                        }}
                    >
                        Done
                    </Button>
                </Stack>
            </Popover>
        </>
    );
}

export function AnalyticsBottom() {
    return null;
}
