import { Stack, Checkbox, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Chip, Type } from '../../general/Chip';
import { Image } from '../../../types/Image';
import { IconButton } from '../../general/IconButton';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import { transferInProcess } from '../../../util/irdbImage';
import { useCopyToClipboard } from 'usehooks-ts';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import { CardType, MetaContent, MetaType, TCardType } from '../../../types/MetaTypes';
import { metaFieldForMetaType, metaContentForMetaType } from '../../../contexts/MetaContext';
import ArtCardStack from './MetaData/ArtCardStack';
import GeneralCardStack from './MetaData/GeneralCardStack';
import ContactCardStack from './MetaData/ContactCardStack';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import PrivateIndicator from '../../metadata/PrivateIndicator';
import { WithMetaErrorsProvider } from '../../../contexts/specialized/MetaErrorsContext';

interface IrcodeImageProps {
    image: Image;
}

const IrcodeImage = ({ image }: IrcodeImageProps) => {
    let dimImage = false;
    if (image.imageStatus === 'draft' || transferInProcess(image)) {
        dimImage = true;
    }

    return (
        <Box
            sx={{
                position: 'relative',
                flexShrink: 0,
                width: { xs: '100%', sm: 140 },
                height: { xs: 200, sm: 140 },
                overflow: 'hidden',
            }}
        >
            <Box
                component="img"
                src={image.imageUrl}
                alt=""
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: { xs: '100%', sm: 140 },
                    height: { xs: 200, sm: 140 },
                    objectFit: 'cover',
                    ...(dimImage ? { filter: 'saturate(50%) brightness(70%)' } : {}),
                }}
                loading="lazy"
            />
            <PrivateIndicator iconMode image={image} position="absolute" top={6} left={6} />
            {transferInProcess(image) && (
                <Chip
                    label="Pending transfer"
                    type={Type.Warning}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
        </Box>
    );
};

interface Props {
    image: Image;
    showCheckbox?: boolean;
    isSelected?: boolean;
    onSelected?: (selected: boolean) => void;
    isEditing?: boolean;
    editingTypes?: MetaType[];
    copyOnConfirm?: (metaType: MetaType, metaContent: MetaContent) => void;
    onChange?: (metaType: MetaType, metaContent: MetaContent) => void;
    onEdit: () => void;
    onReplace?: (image: Image, metaType?: MetaType) => void;
    onCopy?: (image: Image, metaType?: MetaType) => void;
    // onCrop?: () => void;
}

function OwnedIrcodeRow({
    image,
    showCheckbox = false,
    isSelected = false,
    onSelected,
    isEditing = false,
    editingTypes = [],
    copyOnConfirm,
    onChange,
    onEdit,
    onReplace,
    onCopy,
    // onCrop,
}: Props) {
    const navigate = useNavigate();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const [, copyToClipboard] = useCopyToClipboard();
    const { isMobile, darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm, setSuccessMessage, setErrorMessage } = useContext(FeedbackContext) as TFeedback;

    const url = `/dashboard/ircodes/${image.imageID}`;
    const cardType =
        (metaFieldForMetaType(image.metaArray, MetaType.CardType)?.metaContent as TCardType | undefined)?.cardType ??
        CardType.ArtCard;
    const imageTitle =
        cardType === CardType.Contact ?
            `${metaContentForMetaType(image, MetaType.FirstName)?.firstName} ${metaContentForMetaType(image, MetaType.LastName)?.lastName}`
        :   metaContentForMetaType(image, MetaType.Title)?.title;
    const isUntitled = !imageTitle;

    return (
        <Stack
            key={image.imageID}
            direction="row"
            spacing={2}
            sx={{
                alignItems: 'center',
                height: {
                    xs: 'auto',
                    sm: isEditing ? 'auto' : 140,
                },
                scrollMargin: 20,
            }}
        >
            {!isMobile && showCheckbox && (
                <Checkbox
                    checked={isSelected}
                    onChange={event => {
                        onSelected?.(event.target.checked);
                    }}
                />
            )}
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{
                    flexGrow: 1,
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    p: {
                        xs: 0,
                        sm: isEditing ? 2 : 0,
                    },
                    paddingBottom: {
                        xs: 2,
                        sm: isEditing ? 2 : 0,
                    },
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                }}
                onClick={event => {
                    if (isEditing) {
                        return;
                    }

                    event.stopPropagation();
                    navigateAndScrollToTop(url);
                }}
            >
                <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                        pl: 2,
                        alignItems: 'flex-start',
                        alignSelf: 'flex-start',
                        top: '5.5em',
                        position: 'sticky',
                    }}
                >
                    <IrcodeImage image={image} />
                    {onCopy && (
                        <Stack
                            spacing={0}
                            sx={{
                                // pl: 2,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Button
                                sx={{
                                    color: darkMode ? Color.White : Color.Black,
                                    opacity: 0.8,
                                }}
                                onClick={async () => {
                                    if (
                                        await confirm({
                                            title: 'Confirm Copy',
                                            message: 'Copy all metadata across to other IRCODES in this view?',
                                        })
                                    ) {
                                        onCopy(image);
                                    }
                                }}
                            >
                                <i
                                    className="fa-regular fa-copy"
                                    style={{
                                        marginRight: '0.5em',
                                    }}
                                ></i>{' '}
                                Copy All
                            </Button>
                            {editingTypes.includes(MetaType.Link) && (
                                <Button
                                    sx={{
                                        color: darkMode ? Color.White : Color.Black,
                                        opacity: 0.8,
                                    }}
                                    onClick={async () => {
                                        if (
                                            await confirm({
                                                title: 'Confirm Copy',
                                                message: 'Copy all links across to other IRCODES in this view?',
                                            })
                                        ) {
                                            onCopy(image, MetaType.Link);
                                        }
                                    }}
                                >
                                    <i
                                        className="fa-regular fa-copy"
                                        style={{
                                            marginRight: '0.5em',
                                        }}
                                    ></i>{' '}
                                    Copy All Links
                                </Button>
                            )}
                            {editingTypes.includes(MetaType.ProductLink) && (
                                <Button
                                    sx={{
                                        color: darkMode ? Color.White : Color.Black,
                                        opacity: 0.8,
                                    }}
                                    onClick={async () => {
                                        if (
                                            await confirm({
                                                title: 'Confirm Copy',
                                                message: 'Copy all product links across to other IRCODES in this view?',
                                            })
                                        ) {
                                            onCopy(image, MetaType.ProductLink);
                                        }
                                    }}
                                >
                                    <i
                                        className="fa-regular fa-copy"
                                        style={{
                                            marginRight: '0.5em',
                                        }}
                                    ></i>{' '}
                                    Copy All Products
                                </Button>
                            )}
                        </Stack>
                    )}
                    {/* { isEditing &&
                        <Button
                            sx={{
                                color: darkMode ? Color.White : Color.Black,
                                opacity: .8,
                            }}
                            onClick={onCrop}
                        >
                            <i
                                className="fa-regular fa-crop"
                                style={{
                                    marginRight: '0.5em',
                                }}
                            />
                            Crop
                    </Button>
                    } */}
                </Stack>

                {isEditing && (
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        {cardType === CardType.ArtCard && (
                            <ArtCardStack
                                image={image}
                                meta={image.metaArray}
                                isEditing={isEditing}
                                editingTypes={editingTypes}
                                copyOnConfirm={(metaType, metaContent) => copyOnConfirm?.(metaType, metaContent)}
                                onChange={(metaType, metaContent) => onChange?.(metaType, metaContent)}
                            />
                        )}
                        {cardType === CardType.General && (
                            <GeneralCardStack
                                image={image}
                                meta={image.metaArray}
                                isEditing={isEditing}
                                editingTypes={editingTypes}
                                copyOnConfirm={(metaType, metaContent) => copyOnConfirm?.(metaType, metaContent)}
                                onChange={(metaType, metaContent) => onChange?.(metaType, metaContent)}
                            />
                        )}
                        {cardType === CardType.Contact && (
                            <ContactCardStack
                                image={image}
                                meta={image.metaArray}
                                isEditing={isEditing}
                                editingTypes={editingTypes}
                                copyOnConfirm={(metaType, metaContent) => copyOnConfirm?.(metaType, metaContent)}
                                onChange={(metaType, metaContent) => onChange?.(metaType, metaContent)}
                            />
                        )}
                    </Stack>
                )}

                {!isEditing && (
                    <>
                        <Stack
                            direction="column"
                            spacing={1}
                            sx={{
                                flexGrow: 1,
                                alignItems: 'flex-start',
                                paddingX: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    flexGrow: 1,
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '1.25em',
                                    fontWeight: 400,
                                    lineHeight: '1.25em',
                                    wordBreak: 'break-word',
                                    textOverflow: 'ellipsis',

                                    maxHeight: '3.75em', // 3x line height, seems to work...
                                    overflow: 'hidden',
                                    msTextOverflow: 'ellipsis',
                                    fontStyle: isUntitled ? 'italic' : undefined,
                                    pr: 1,
                                }}
                            >
                                {imageTitle || 'Untitled'}
                            </Typography>
                            {image.campaignName && (
                                <Typography
                                    sx={{
                                        flexGrow: 1,
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '.9em',
                                        fontWeight: 400,
                                        lineHeight: '1.25em',
                                        wordBreak: 'break-word',
                                        textOverflow: 'ellipsis',

                                        maxHeight: '3.75em', // 3x line height, seems to work...
                                        overflow: 'hidden',
                                        msTextOverflow: 'ellipsis',
                                        // TODO: Add ellipsis if possible.
                                    }}
                                >
                                    {image.campaignName}
                                </Typography>
                            )}
                            {(metaContentForMetaType(image, MetaType.ProductLink)?.links ?? []).length > 0 && (
                                <Typography
                                    sx={{
                                        flexGrow: 1,
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '.9em',
                                        fontWeight: 400,
                                        lineHeight: '1.25em',
                                        wordBreak: 'break-word',
                                        textOverflow: 'ellipsis',
                                        maxHeight: '3.75em',
                                        overflow: 'hidden',
                                        msTextOverflow: 'ellipsis',
                                    }}
                                >
                                    {(metaContentForMetaType(image, MetaType.ProductLink)?.links ?? []).length}{' '}
                                    {(metaContentForMetaType(image, MetaType.ProductLink)?.links ?? []).length === 1 ?
                                        'product'
                                    :   'products'}
                                </Typography>
                            )}

                            {typeof image.countViews === 'number' && (
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '.75em',
                                        fontWeight: 400,
                                        lineHeight: '1.25em',
                                        letterSpacing: '0.15em',
                                    }}
                                >
                                    {image.countViews} view{image.countViews === 1 ? '' : 's'}
                                </Typography>
                            )}
                        </Stack>
                        <Stack
                            direction={{ xs: 'row', sm: 'column', md: 'row' }}
                            spacing={1}
                            sx={{
                                paddingX: 2,
                            }}
                        >
                            {/* <IconButton
                                icon="fa-chart-line"
                                tooltip="Analytics"
                                onClick={ (event) => {
                                    event.stopPropagation();
                                    navigate(`/dashboard/myircodes/${image.imageID}#analytics`);
                                    window.scrollTo(0, 0);
                                } }
                            /> */}
                            <IconButton
                                icon="fa-pen"
                                tooltip="Edit"
                                onClick={event => {
                                    event.stopPropagation();
                                    onEdit();
                                }}
                            />
                            <IconButton
                                icon="fa-link"
                                tooltip="Copy to Clipboard"
                                onClick={event => {
                                    event.stopPropagation();
                                    copyToClipboard(`${window.location.origin}${url}`)
                                        .then(() => {
                                            setSuccessMessage('Copied to clipboard');
                                        })
                                        .catch(() => {
                                            setErrorMessage('Failed to copy to clipboard');
                                        });
                                }}
                            />
                        </Stack>
                    </>
                )}
            </Stack>
        </Stack>
    );
}

export default WithMetaErrorsProvider(OwnedIrcodeRow);
