import * as React from 'react';
import { Box, CircularProgress, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import MediaContext, { ImageOperation, TMedia, Upload } from '../../../../contexts/MediaContext';
import { Image } from '../../../../types/Image';
import { Chip, Type } from '../../../general/Chip';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import PageHeader from '../PageHeader';
import { Select } from '../../../general/Select';
import { IrcodeInformationBottom, IrcodeInformationTop } from './IrcodeInformation';
import useLinkableTabs from '../../../../hooks/useLinkableTabs';
import { ITransferIrcode, TransferIrcodeBottom, TransferIrcodeTop } from '../MyIrcode/TransferIrcode';
import { PendingTransferTop } from '../MyIrcode/PendingTransfer';
import FeedbackContext, { TFeedback } from '../../../../contexts/FeedbackContext';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { Color } from '../../../../Color';
import { transferInProcess } from '../../../../util/irdbImage';
import MetaContext, { EmptyMetaData, MetaData, TMeta } from '../../../../contexts/MetaContext';
import { AnalyticsTop } from '../MyIrcode/Analytics';
import useSaveCropped from 'src/hooks/useSaveCropped';
import usePageTitle from 'src/hooks/usePageTitle';
import { useScopeMetaErrorsActions } from '../../../../hooks/metaErrors/useScopeMetaErrors';
import { WithMetaErrorsProvider } from '../../../../contexts/specialized/MetaErrorsContext';
import useImageMetaContent from '../../../../hooks/image/useImageMetaContent';

enum TabOption {
    AddOns = 'AddOns',
    Analytics = 'Analytics',
    Information = 'Information',
    Transfer = 'Transfer IRCODE',
}

const tabs = [
    // TabOption.Analytics,
    TabOption.Information,
    // TabOption.AddOns,
    // TabOption.Transfer,
];

function Ircode() {
    const navigate = useNavigate();
    const { id: campaignId, imageId } = useParams();
    const saveCropped = useSaveCropped();

    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { load, status } = useContext(MediaContext) as TMedia;
    const { fetch } = useContext(MetaContext) as TMeta;

    const [tab, setTab] = useLinkableTabs(tabs);
    const currentTab = tabs[tab];

    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const [image, setImage] = useState<Image>();
    const [imageStatus, setImageStatus] = useState<'publish' | 'draft' | undefined>(image?.imageStatus);
    const [cropResultOperation, setCropResultOperation] = useState<ImageOperation<Upload>>();
    const [imageExists, setImageExists] = useState<Image>();
    const [transferIrcode, setTransferIrcode] = useState<ITransferIrcode | undefined>();

    useAuth(async () => {
        // console.log('id changed', id);
        if (imageId) {
            try {
                setIsLocalLoading(true);
                setMeta(EmptyMetaData);
                const i = await load(imageId);
                setImage(i);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLocalLoading(false);
            }
        }
    }, [imageId]);

    const [meta, setMeta] = useState<MetaData>(EmptyMetaData);
    const [isMetaLoading, setIsMetaLoading] = useState(false);
    const { setErrors: setMetaErrors, setScopeId: setMetaErrorsScopeId } = useScopeMetaErrorsActions();

    useEffect(() => {
        if (campaignId) {
            setMetaErrorsScopeId(campaignId);
        }
    }, [setMetaErrorsScopeId, campaignId]);

    // Clear meta errors on unmount
    // useEffect(() => () => setMetaErrors({}), [setMetaErrors]);

    useEffect(() => {
        console.log('image changed');
        if (image) {
            setImageStatus(image.imageStatus);

            // Load MetaData
            setIsMetaLoading(true);
            fetch(image?.imageID, false)
                .then(data => {
                    console.log('meta data loaded', data);
                    setMeta(data);
                })
                .catch(error => {
                    console.error(error);
                    setMeta(EmptyMetaData);
                })
                .finally(() => {
                    setIsMetaLoading(false);
                });
        } else {
            setMeta(EmptyMetaData);
        }
    }, [image]);

    const imageNotFound = !isLocalLoading && image === undefined;
    const imageTitle = useImageMetaContent(image, 'Title')?.title || 'Untitled';

    usePageTitle(image ? imageTitle : '');

    return (
        <Stack
            direction="column"
            spacing={4}
            sx={{
                p: 4,
            }}
        >
            <PageHeader
                title={() => {
                    return (
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                navigate(`/enterprise/campaigns/${image?.campaignID}#ircodes`);
                            }}
                        >
                            <i className="fa-regular fa-arrow-left fa-2xl"></i>
                            <Typography
                                sx={{
                                    marginLeft: 2,
                                    fontFamily: 'Nocturne Serif',
                                    fontSize: '2.5em',
                                    fontWeight: 400,
                                    lineHeight: '1em',
                                    letterSpacing: '-0.4px',
                                    textAlign: 'left',
                                }}
                            >
                                {image?.campaignName}
                            </Typography>
                        </Stack>
                    );
                }}
            />

            {isLocalLoading && (
                <CircularProgress
                    sx={{
                        alignSelf: 'center',
                    }}
                />
            )}

            {image && (
                <Stack
                    direction="column"
                    spacing={4}
                    // sx={{
                    // }}
                >
                    <Paper
                        sx={{
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                        }}
                        elevation={0}
                    >
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            spacing={0}
                            // sx={{
                            // }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    width: { xs: '100%', md: 280 },
                                    height: { xs: 200, md: 280 },
                                    objectFit: 'contain',
                                }}
                                src={cropResultOperation?.cropped?.url ?? image.imageUrl}
                                alt=""
                            />
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    p: 4,
                                }}
                            >
                                {transferInProcess(image) && <Chip label="Pending transfer" type={Type.Warning} />}
                                {!image.transferInProcess && imageStatus && (
                                    <Select
                                        options={[
                                            { value: 'publish', label: 'Publish' },
                                            { value: 'draft', label: 'Draft' },
                                        ]}
                                        value={imageStatus}
                                        onChange={async value => {
                                            setShowLoading(true);
                                            await status(image.imageID, value as 'publish' | 'draft');
                                            const i = await load(image.imageID);
                                            setImage(i);
                                            setShowLoading(false);
                                        }}
                                    />
                                )}
                                <Stack direction="column" spacing={0} sx={{}}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nocturne Serif',
                                            fontSize: '32px',
                                            fontWeight: 600,
                                            lineHeight: '40px',
                                            letterSpacing: '-0.03em',
                                            textAlign: 'left',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {imageTitle}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={
                                        {
                                            // paddingX: 2,
                                        }
                                    }
                                >
                                    {/* <Chip
                                        label="Verify"
                                        type={Type.Warning}
                                    />
                                    <Chip
                                        label="NFT"
                                        // type={Type.Warning}
                                    /> */}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>

                    <Paper
                        sx={{
                            p: 1,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White, // Color.LightLavenderLightMode,
                        }}
                        elevation={0}
                    >
                        <Tabs
                            value={tab}
                            onChange={(event: SyntheticEvent, newValue: number) => {
                                setTab(newValue);
                            }}
                            // onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map(tab => (
                                <Tab key={tab} label={tab} />
                            ))}
                        </Tabs>

                        {currentTab === TabOption.Analytics && (
                            <AnalyticsTop
                                imageID={image.imageID}
                                setTabInformation={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                // image={image}
                                // meta={meta}
                                // setMeta={setMeta}
                            />
                        )}

                        {currentTab === TabOption.Information && (
                            <IrcodeInformationTop
                                onCropped={result => {
                                    setCropResultOperation(result);
                                }}
                                image={image}
                                meta={meta}
                                setMeta={setMeta}
                            />
                        )}

                        {currentTab === TabOption.AddOns && <>Add-Ons Top</>}

                        {currentTab === TabOption.Transfer && image.transferInProcess === 0 && (
                            <TransferIrcodeTop
                                imageID={image.imageID}
                                transferIrcode={transferIrcode}
                                setTransferIrcode={setTransferIrcode}
                                setTabInformation={() => {
                                    setTab(tabs.indexOf(TabOption.Information));
                                }}
                                // imageOperation={imageOperation}
                            />
                        )}

                        {currentTab === TabOption.Transfer && transferInProcess(image) && (
                            <PendingTransferTop
                                image={image}
                                // transferIrcode={transferIrcode}
                                // setTransferIrcode={setTransferIrcode}
                                // imageOperation={imageOperation}
                            />
                        )}
                    </Paper>

                    {currentTab === TabOption.Analytics && <></>}

                    {currentTab === TabOption.Information && (
                        <IrcodeInformationBottom
                            image={image}
                            meta={meta}
                            onMetaSaveErrors={setMetaErrors}
                            onUpdate={async () => {
                                setShowLoading(true);
                                // const imageId = image.imageID;
                                if (cropResultOperation) {
                                    const { promise } = saveCropped(cropResultOperation, image);
                                    const res = await promise;
                                    if (res.operation.Results?.ImageAlreadyExists) {
                                        setImageExists(res.operation.Results.Image);
                                        return;
                                    }
                                }
                                setCropResultOperation(undefined);
                                setShowLoading(false);
                                navigate(`/enterprise/campaigns/${campaignId}`);
                            }}
                        />
                    )}

                    {currentTab === TabOption.AddOns && <>AddOns Bottom</>}

                    {currentTab === TabOption.Transfer && image.transferInProcess === 0 && (
                        <TransferIrcodeBottom imageID={image.imageID} transferIrcode={transferIrcode} />
                    )}
                </Stack>
            )}

            {imageNotFound && (
                <Typography
                    variant="mainFont6"
                    sx={{
                        paddingY: 8,
                        textAlign: 'center',
                    }}
                >
                    Sorry, that IRCODE cannot be found.
                </Typography>
            )}
        </Stack>
    );
}

export default WithMetaErrorsProvider(Ircode);
