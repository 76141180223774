import { Box, CircularProgress, IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useMemo } from 'react';
import { ZIndex } from '../../App';
import { Color } from '../../Color';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import MediaContext, { TMedia } from '../../contexts/MediaContext';
import { Image } from '../../types/Image';
import { metaContentForMetaType } from '../../contexts/MetaContext';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { MetaType } from '../../types/MetaTypes';
import Drawer from '../general/Drawer';
import Info from '../image/Info';
import PrivateIndicator from '../metadata/PrivateIndicator';

interface Props {
    images: Image[];
    showLoading: boolean;
    emptyMessage?: string;
    onRefresh: () => void;
    onUnmount?: () => void;
    when: (image: Image) => number;
    selectedImageId?: string;
    setSelectedImageId: (imageId?: string) => void;
}

export default function ImageList({
    images,
    showLoading,
    emptyMessage,
    onRefresh,
    onUnmount,
    when,
    selectedImageId,
    setSelectedImageId,
}: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm } = useContext(FeedbackContext) as TFeedback;
    const { remove } = useContext(MediaContext) as TMedia;

    const selectedImage = useMemo(
        () => images.find(image => image.imageID === selectedImageId),
        [selectedImageId, images],
    );
    // const imageOperation = useMemo(() => selectedImage && imageOperationFromImage(selectedImage), [selectedImage]);

    const formatDate = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        const now = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(now.getDate() - 7);

        if (date >= oneWeekAgo && date <= now) {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            return daysOfWeek[date.getDay()];
        } else {
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            const year = date.getFullYear();
            return `${month} ${day}, ${year}`;
        }
    };

    useEffect(() => {
        console.log('Mounting ImageList');
        return () => {
            console.log('Unmounting ImageList');
            onUnmount?.();
        };
    }, []);

    return (
        <>
            {/* TODO: Implemenet loading */}
            {showLoading ?
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress
                        sx={{
                            m: 4,
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    />
                </Box>
            : images.length === 0 && emptyMessage ?
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            // display: 'flex',
                            // flexGrow: 1,

                            // p: 4,
                            fontFamily: 'Nocturne Serif',
                            fontSize: '32px',
                            fontWeight: 600,
                            lineHeight: '40px',
                            letterSpacing: '-0.03em',
                            textAlign: 'center',

                            color: darkMode ? Color.White : Color.Black,
                            // backgroundColor: 'pink',
                        }}
                    >
                        {emptyMessage}
                    </Typography>
                </Box>
            :   <Stack
                    direction="column"
                    spacing={2}
                    // cols={1}
                    // className="inline-block h-max"
                    // style={{ width: "100%", display: "block" }}
                    // onScroll={(event) => {
                    //     console.log("onScroll");
                    // }}
                    sx={{
                        // mx: 2,
                        px: 2,
                    }}
                >
                    {images.map(
                        (
                            image,
                            i,
                            _,
                            artistName = metaContentForMetaType(image, MetaType.ArtistName)?.name,
                            title = metaContentForMetaType(image, MetaType.Title)?.title,
                        ) => (
                            <Stack
                                key={image.imageID}
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                sx={{
                                    overflow: 'hidden',
                                    position: 'relative',
                                    pb: '16px',
                                    ...(i < images.length - 1 && {
                                        '&::after': {
                                            content: '""',
                                            display: 'block',
                                            height: '2px',
                                            backgroundColor:
                                                darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                            position: 'absolute',
                                            top: 'calc(100% - 2px)',
                                            left: 0,
                                            right: 0,
                                        },
                                    }),
                                }}
                                onClick={() => {
                                    // console.log('imageOperation', imageOperation);
                                    setSelectedImageId(image.imageID);
                                }}
                            >
                                <Box
                                    position="relative"
                                    sx={{
                                        width: { xs: 134 },
                                        height: { xs: 112 },
                                        borderRadius: '8px',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 'inherit',
                                            height: 'inherit',
                                            objectFit: 'cover',
                                            borderRadius: 'inherit',
                                        }}
                                        src={image.imageUrl}
                                        alt=""
                                        loading="lazy"
                                    />
                                    <PrivateIndicator iconMode image={image} position="absolute" top={6} left={6} />
                                </Box>
                                <Stack height="100%" direction="column" justifyContent="space-between">
                                    <Typography
                                        sx={{
                                            // display: 'flex',
                                            fontSize: '14px',
                                            color: Color.Purple,
                                        }}
                                    >
                                        {image.ImageUser.userName}
                                    </Typography>
                                    <Stack
                                        direction="column"
                                        sx={{
                                            flexGrow: 1,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {artistName && (
                                            <Typography
                                                variant="mainFont6"
                                                sx={{
                                                    fontFamily: 'Nocturne Serif',
                                                    fontSize: '18px',

                                                    lineHeight: '20px',

                                                    // marginLeft: 2,
                                                    // fontSize: '1.5rem',

                                                    color: darkMode ? Color.White : Color.Black,
                                                }}
                                            >
                                                {artistName}
                                            </Typography>
                                        )}
                                        <Typography
                                            // variant="mainFont6"
                                            sx={{
                                                fontFamily: 'Nocturne Serif',
                                                fontSize: '18px',
                                                fontWeight: 400,
                                                fontStyle: 'italic',

                                                lineHeight: '20px',
                                                letterSpacing: '0em',
                                                textAlign: 'left',
                                                wordBreak: 'break-word',
                                                // marginRight: 2,

                                                color: darkMode ? Color.White : Color.Black,
                                                ...(!title && {
                                                    opacity: 0.5,
                                                }),
                                            }}
                                        >
                                            {title || 'Untitled'}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row">
                                        <Typography
                                            sx={{
                                                color: darkMode ? Color.White : Color.Black,
                                            }}
                                            variant="webAccountType"
                                        >
                                            {image.countViews} {image.countViews === 1 ? 'View' : 'Views'} •{' '}
                                            {image.countQueries} {image.countQueries === 1 ? 'Scan' : 'Scans'} •{' '}
                                            {formatDate(when(image))}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <IconButton
                                    style={{
                                        // position: 'absolute',
                                        display: 'none',
                                        right: 0,
                                        bottom: 0,
                                    }}
                                    onClick={async event => {
                                        event.stopPropagation();
                                        if (
                                            await confirm({
                                                title: 'IRCODE',
                                                message: 'Are you sure you want to delete this IRCODE?',
                                            })
                                        ) {
                                            await remove(image.imageID);
                                            onRefresh();
                                        }
                                    }}
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </IconButton>
                            </Stack>
                        ),
                    )}
                </Stack>
            }

            {/* Image Info */}
            <SwipeableDrawer
                anchor="bottom"
                open={selectedImageId !== undefined}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                onClose={function (event: SyntheticEvent<{}, Event>): void {
                    setSelectedImageId(undefined);
                }}
                onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                style={{
                    zIndex: ZIndex.ImageList,
                }}
            >
                <Drawer>
                    <Info
                        image={selectedImage}
                        onSaved={id => setSelectedImageId(id)}
                        onClose={() => setSelectedImageId(undefined)}
                    />
                </Drawer>
            </SwipeableDrawer>
        </>
    );
}
