import { useContext } from 'react';
import { Box } from '@mui/material';
import { Color } from 'src/Color';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { Point, ResponsiveLine } from '@nivo/line';
import { Interval } from 'src/types/Analytics';

export interface Datum {
    x: string | number;
    y: number;
    [key: string]: any;
}

export interface Serie {
    id: string | number;
    data: readonly Datum[];
    [key: string]: any;
}

interface Props {
    data: Serie[];
    interval: Interval;
    showLegend?: boolean;
    tooltip?: (point: Point, serie: Serie) => JSX.Element;
}

export default function ResponsiveLineChart({ data, interval, showLegend, tooltip }: Props) {
    console.log('ResponsiveLineChart', data, interval);

    const { darkMode } = useContext(ThemeContext) as TTheme;

    const isHourly = interval === Interval.Hourly || interval === Interval.Minutely;
    const hasData = data[0]?.data?.length > 0;
    let tickXValues, tickYValues;

    if (hasData) {
        // constrain y axis to 10 values at most
        const allYValues = data.flatMap(serie => serie.data.map(d => d.y));
        const maxYValue = Math.ceil(Math.max(...allYValues));
        tickYValues = Array.from(
            { length: Math.min(10, maxYValue) },
            (_, index) => (index + 1) * Math.ceil(maxYValue / 10),
        );
    }

    return (
        <Box
            style={{
                height: 300,
                width: '100%',
            }}
        >
            {hasData && (
                <ResponsiveLine
                    data={data}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: tickYValues ? tickYValues[tickYValues.length - 1] : 'auto',
                    }}
                    curve="monotoneX"
                    useMesh={true}
                    margin={{ top: 30, right: 20, bottom: isHourly ? 100 : 70, left: 50 }}
                    legends={
                        showLegend ?
                            [
                                {
                                    // anchor: 'bottom-left',
                                    anchor: 'center',
                                    // offset: 0,
                                    // translateY: 30,
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 150,
                                    itemsSpacing: 50,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemTextColor: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    itemOpacity: 0.75,
                                    symbolSize: 10,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                                itemOpacity: 1,
                                            },
                                        },
                                    ],
                                },
                            ]
                        :   undefined
                    }
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fill: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    fontSize: 12,
                                    fontWeight: 400,
                                },
                            },
                        },
                        grid: {
                            line: {
                                stroke: darkMode ? 'rgba(235, 235, 245, 0.16)' : '#ddd',
                            },
                        },
                        crosshair: {
                            line: {
                                stroke: darkMode ? 'rgba(235, 235, 245, 0.16)' : '#ddd',
                            },
                        },
                    }}
                    axisLeft={{
                        tickValues: tickYValues,
                    }}
                    axisBottom={{
                        tickRotation: -45,
                        tickValues: tickXValues,
                        // legend: <Typography>Time</Typography>,
                        format: value => {
                            return '';
                            const date = new Date(value);
                            return isHourly ?
                                    date.toLocaleString('en-US', {
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        hour12: true,
                                    })
                                :   date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
                        },
                    }}
                    tooltip={({ point }) => {
                        if (!tooltip) {
                            return null;
                        }

                        const serie = data.find(item => item.id === point.serieId);

                        if (!serie) {
                            return null;
                        }

                        return (
                            <div
                                style={{
                                    background: !darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    border: '1px solid',
                                    borderColor: darkMode ? 'rgba(235, 235, 245, 0.16)' : '#eee',
                                    padding: '.5em',
                                    fontSize: '.8rem',
                                    opacity: 0.9,
                                }}
                            >
                                {tooltip(point, serie)}
                            </div>
                        );
                    }}
                    colors={line => {
                        return line.color;
                    }}
                />
            )}
        </Box>
    );
}
