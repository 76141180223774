import { Button, Dialog, Stack, Typography } from '@mui/material';
import { create } from 'zustand';
import { createStoreSelectors } from '../../util/zustand';
import useExpiringAction from '../useExpiringAction';

export type NotifyCallback = (title: string, message?: string, ok?: string) => Promise<void>;

export interface NotifyStore {
    promise: { resolve: (value: any) => void; reject: (reason?: any) => void } | undefined;
    title: string;
    message: string;
    ok: string;
    notify: NotifyCallback;
    handleOk: () => void;
    handleClear: () => void;
}

const initState = {
    title: '',
    message: '',
    ok: 'OK',
} as const;

const useNotifyStore = createStoreSelectors(
    create<NotifyStore>()(
        (set, get): NotifyStore => ({
            promise: undefined,
            ...initState,
            notify: (title, message, ok = 'OK') => {
                return new Promise<void>((resolve, reject) => {
                    set({ title, message, ok, promise: { resolve, reject } });
                });
            },
            handleOk: () => {
                const { promise } = get();
                promise?.resolve(true);
                set({ promise: undefined });
            },
            handleClear: () => {
                set(initState);
            },
        }),
    ),
);

/** Don't use this component directly. Instead, use the 'notify' function from the feedback context*/
export const NotifyDialog = () => {
    const promise = useNotifyStore.use.promise();
    const title = useNotifyStore.use.title();
    const message = useNotifyStore.use.message();
    const ok = useNotifyStore.use.ok();
    const handleClear = useNotifyStore.use.handleClear();
    const handleOk = useExpiringAction(200, useNotifyStore.use.handleOk(), handleClear);

    return (
        <Dialog
            open={promise !== undefined}
            sx={{
                zIndex: Number.MAX_SAFE_INTEGER,
                '& .MuiDialog-paper': {
                    minWidth: '30vw',
                    p: 4,
                    borderRadius: 2,
                },
            }}
        >
            <Stack direction="column" spacing={2}>
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '32px',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.03em',
                        textAlign: 'left',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        wordBreak: 'break-word',
                    }}
                >
                    {message}
                </Typography>
                <Button
                    variant="irdbGradient"
                    sx={{
                        height: 56,
                    }}
                    onClick={handleOk}
                >
                    {ok}
                </Button>
            </Stack>
        </Dialog>
    );
};

const useNotify = (): NotifyCallback => {
    return useNotifyStore.use.notify();
};

export default useNotify;
