import ProfileTextField, { ProfileTextFieldProps } from './ProfileTextField';
import { useProfileInfo } from '../../../stores/useProfileState';

import { TAccount } from '../../../stores/ProfileInfoStore';

export interface ProfileEmailFieldProps extends Omit<ProfileTextFieldProps, 'onChange' | 'value'> {}

const helperTextSelector = (state: TAccount) => {
    const authProviders = state.authProviders;
    let helperText = 'Your email address is used for signing in and cannot be changed.';
    if (authProviders.password) {
        return helperText;
    }
    const provider = Object.entries(authProviders).find(([, value]) => value);
    const providerID = provider?.[0];
    if (providerID) {
        helperText = `Your email address is tied to your ${providerID.charAt(0).toUpperCase()}${providerID.split('.')[0].slice(1)} account and cannot be changed.`;
    }
    return helperText;
};

const cb = () => {};

const ProfileEmailField = (props: ProfileEmailFieldProps) => {
    const useSelectors = useProfileInfo().use;
    const email = useSelectors.email();
    const emailFieldDisabled = useSelectors.emailFieldDisabled();
    const helperText = useSelectors(helperTextSelector);
    return (
        <ProfileTextField
            {...props}
            value={email}
            onChange={cb}
            disabled={emailFieldDisabled}
            helperText={emailFieldDisabled ? helperText : undefined}
        />
    );
};

export default ProfileEmailField;
