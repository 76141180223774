import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import MediaContext, { TMedia } from '../../../contexts/MediaContext';
import { Image } from '../../../types/Image';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import PageHeader from './PageHeader';
import BulkUploader from '../uploader/BulkUploader';
import { Color } from '../../../Color';
import MetaContext, {
    EmptyMetaData,
    MetaData as IMetaData,
    metaFieldForMetaType,
    metaIncludes,
    TMeta,
} from '../../../contexts/MetaContext';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { nullUndefinedOrEmpty } from '../../../util/string';
import UserContext, { TUser } from '../../../contexts/UserContext';
import IrcodeContextMenu from '../tools/IrcodeContextMenu';
import { CardType, MetaType, TArtistName, TTitle } from '../../../types/MetaTypes';
import { ILink, TLink } from '../../../types/Link';
import { Link } from './MetaData/Link';
import { Title } from './MetaData/Title';
import { Description } from './MetaData/Description';
import { Tags } from './MetaData/Tags';
import { Custom } from './MetaData/Custom';
import { Price } from './MetaData/Price';
import { GalleryName } from './MetaData/GalleryName';
import { Provenance } from './MetaData/Provenance';
import { Phone } from './MetaData/Phone';
import { Email } from './MetaData/Email';
import { DisplayLocation } from './MetaData/DisplayLocation';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import usePageTitle from 'src/hooks/usePageTitle';
import { ProductLink } from './MetaData/ProductLink';
import { Company } from './MetaData/Company';
import { JobTitle } from './MetaData/JobTitle';
import useImageMetaContent from '../../../hooks/image/useImageMetaContent';

const ArtCard = ({ meta }: { meta: IMetaData }) => {
    const artistName = metaFieldForMetaType(meta.Meta, MetaType.ArtistName);
    const title = metaFieldForMetaType(meta.Meta, MetaType.Title);
    const year = metaFieldForMetaType(meta.Meta, MetaType.Year);
    const medium = metaFieldForMetaType(meta.Meta, MetaType.Medium);
    const size = metaFieldForMetaType(meta.Meta, MetaType.Size);

    const Detail = ({ detail }: { detail: string }) => {
        return (
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '1em',
                    fontWeight: 400,
                    lineHeight: '1.125em',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    // wordBreak: 'break-word',
                    color: 'gray',
                }}
            >
                {detail}
            </Typography>
        );
    };

    const details = [year?.metaContent.year, medium?.metaContent.medium, size?.metaContent.size]
        .filter(detail => !nullUndefinedOrEmpty(detail))
        .map((detail, index) => {
            return <Detail key={index} detail={detail} />;
        });

    return (
        <Stack direction="column">
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 600,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                }}
            >
                {artistName?.metaContent.name ?? 'Anonymous'}
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 600,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                    fontStyle: 'italic',
                }}
            >
                {title?.metaContent.title ?? 'Untitled'}
            </Typography>
            <Stack
                direction="row"
                spacing={1}
                divider={<Detail detail="•" />}
                sx={{
                    marginTop: 2,
                }}
            >
                {details}
            </Stack>
        </Stack>
    );
};

export default function Ircode() {
    const navigate = useNavigate();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { id } = useParams();
    const theme = useTheme();
    const { isMobile, isSmall, darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;
    const { load, fetchSimilarImages } = useContext(MediaContext) as TMedia;
    const { fetch, search } = useContext(MetaContext) as TMeta;

    const ref = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [image, setImage] = useState<Image>();

    const [isProcessing, setIsProcessing] = useState(false);
    const [isLocalLoading, setIsLocalLoading] = useState(false);

    const [isSimilarImagesLoading, setIsSimilarImagesLoading] = useState(false);
    const [similarImages, setSimilarImages] = useState<Image[]>([]);
    const [isMoreByThisArtistLoading, setIsMoreByThisArtistLoading] = useState(false);
    const [moreByThisArtist, setMoreByThisArtist] = useState<Image[]>([]);

    const imageTitle = useImageMetaContent(image, 'Title')?.title || 'Untitled';

    usePageTitle(image ? imageTitle : '');

    useAuth(async () => {
        if (id) {
            try {
                setIsLocalLoading(true);
                const i = await load(id);
                setImage(i);

                if (i.imageUrl) {
                    setIsSimilarImagesLoading(true);
                    fetchSimilarImages(i)
                        .then(similarImages => {
                            setSimilarImages(similarImages.Results);
                        })
                        .catch(error => {
                            console.error('Failed to get similar images:', error);
                        })
                        .finally(() => {
                            setIsSimilarImagesLoading(false);
                        });
                } else {
                    setSimilarImages([]);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLocalLoading(false);
            }
        }
    }, [id]);

    useLayoutEffect(() => {
        // if (imageLoaded) {
        setTimeout(() => {
            // This is never correct the first time, so we need to wait a bit
            if (ref.current) {
                const { width, height } = (ref.current as HTMLElement).getBoundingClientRect();
                // console.log("width", width);
                // console.log("height", height);
                // onLayout(height);
            }
        }, 100);
        // }
    }, []);

    const [meta, setMeta] = useState<IMetaData>(EmptyMetaData);
    const [isMetaLoading, setIsMetaLoading] = useState(false);
    useEffect(() => {
        if (image?.imageID) {
            setIsMetaLoading(true);
            fetch(image?.imageID)
                .then(data => {
                    setMeta(data);
                })
                .catch(error => {
                    console.error(error);
                    setMeta(EmptyMetaData);
                })
                .finally(() => {
                    setIsMetaLoading(false);
                });
        } else {
            setMeta(EmptyMetaData);
        }
    }, [image]);
    useEffect(() => {
        // console.log('useEffect meta', meta);
        const metaFields = meta.Meta.filter(metaField => metaField.metaType === MetaType.ArtistName);
        if (metaFields.length > 0) {
            const artistNameMetaField = metaFields[0];
            const content = artistNameMetaField.metaContent as TArtistName;
            setIsMoreByThisArtistLoading(true);
            search(content.name, false)
                .then(images => {
                    // console.log('images', images);
                    setMoreByThisArtist(images.Results.filter(image => image.imageID !== id).slice(0, 6));
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    setIsMoreByThisArtistLoading(false);
                });
        }
    }, [meta]);

    let links: ILink[] = [];
    const linksMetaField = meta.Meta.filter(metaField => metaField.metaType === MetaType.Link);
    if (linksMetaField.length > 0) {
        const linksMetaData = linksMetaField[0];
        const content = linksMetaData.metaContent as TLink;
        links = content.links ?? [];
    }

    const imageNotFound = !isLocalLoading && image === undefined;
    const cardType = useImageMetaContent(image, 'CardType')?.cardType ?? CardType.ArtCard;

    const Header = image && (
        <Stack
            direction="row"
            spacing={2}
            sx={{
                p: isSmall ? 2 : 0,
                alignItems: 'center',
            }}
        >
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    flexGrow: 1,
                }}
                onClick={() => {
                    if (!image.campaignID) {
                        navigate(`/dashboard/profile/${image.ImageUser.userID}`);
                    }
                }}
            >
                <Avatar
                    src={image.ImageUser.profileUrl}
                    sx={{
                        fontSize: '1em',
                        width: '1.625em',
                        height: '1.625em',
                        borderRadius: '50%',
                    }}
                />
                <Typography
                    sx={{
                        flexGrow: 1,
                        fontFamily: 'Nunito Sans',
                        fontSize: '1em',
                        fontWeight: '600',
                        lineHeight: '1.5em',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {image.ImageUser.fullName}
                </Typography>
            </Stack>
            {image.ImageUser.userID === user?.userID && (
                <>
                    <IconButton color="primary" onClick={event => setAnchorEl(event.currentTarget)} size="small">
                        <i className="fa-regular fa-gear" />
                    </IconButton>
                    <IrcodeContextMenu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        handleClose={() => {
                            setAnchorEl(null);
                        }}
                        imageID={image.imageID}
                    />
                </>
            )}
        </Stack>
    );

    return (
        <Stack
            direction="column"
            spacing={4}
            sx={{
                p: 4,

                // TODO: Still needed?
                flexGrow: 1,
                width: '100%',
            }}
        >
            {!isMobile && (
                <PageHeader
                    title={() => {
                        return window.history.length > 1 ?
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    <i className="fa-regular fa-arrow-left fa-2xl"></i>
                                </Stack>
                            :   <>&nbsp;</>;
                    }}
                />
            )}
            {/* { (isLoading) && (
                <CircularProgress
                    sx={{
                        alignSelf: 'center',
                    }}
                />
            )} */}
            {image && (
                <Paper
                    sx={{
                        borderRadius: 2,
                        backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                    }}
                    elevation={0}
                >
                    <Stack direction="column" spacing={2}>
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            sx={{
                                // In xs and sm, 'flex-start' caused the name/ellipsis to collapse
                                alignItems: { xs: 'normal', md: 'flex-start' },
                            }}
                        >
                            {isSmall && Header}
                            <Box
                                component="img"
                                sx={{
                                    width: { xs: '100%', md: 240, lg: 480 },
                                    height: 'auto',
                                    borderRadius: 0,
                                    marginLeft: { xs: 0, md: 2 },
                                    objectFit: 'contain',
                                }}
                                src={image.imageUrl}
                                alt=""
                            />
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'stretch',
                                    p: 4,
                                    fontSize: { xl: '1.4em' },
                                    overflow: 'hidden',
                                }}
                            >
                                {!isSmall && Header}
                                {isMetaLoading && (
                                    <Stack
                                        direction="column"
                                        spacing={1}
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <Stack direction="row" spacing={2}>
                                            <Skeleton animation="wave" height={20} width="30%" />
                                            <Skeleton animation="wave" height={20} width="70%" />
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Skeleton animation="wave" height={20} width="30%" />
                                            <Skeleton animation="wave" height={20} width="70%" />
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Skeleton animation="wave" height={20} width="30%" />
                                            <Skeleton animation="wave" height={20} width="70%" />
                                        </Stack>
                                        <Skeleton animation="wave" height={20 * 5} width="100%" />
                                    </Stack>
                                )}
                                {cardType === CardType.ArtCard && (
                                    <Stack spacing={2} divider={<Divider />}>
                                        <Stack spacing={2}>
                                            <ArtCard meta={meta} />
                                            <Link metaField={metaFieldForMetaType(meta.Meta, MetaType.Link)} />
                                            <ProductLink
                                                metaField={metaFieldForMetaType(meta.Meta, MetaType.ProductLink)}
                                            />
                                        </Stack>
                                        {metaIncludes(meta.Meta, [
                                            MetaType.Description,
                                            MetaType.Price,
                                            MetaType.GalleryName,
                                            MetaType.DisplayLocation,
                                            MetaType.Provenance,
                                            MetaType.Email,
                                            MetaType.Phone,
                                            MetaType.Tags,
                                            MetaType.Custom,
                                        ]) && (
                                            <Stack spacing={2}>
                                                <Description
                                                    metaField={metaFieldForMetaType(meta.Meta, MetaType.Description)}
                                                />
                                                <Price metaField={metaFieldForMetaType(meta.Meta, MetaType.Price)} />
                                                <GalleryName
                                                    metaField={metaFieldForMetaType(meta.Meta, MetaType.GalleryName)}
                                                />
                                                <DisplayLocation
                                                    metaField={metaFieldForMetaType(
                                                        meta.Meta,
                                                        MetaType.DisplayLocation,
                                                    )}
                                                />
                                                <Provenance
                                                    metaField={metaFieldForMetaType(meta.Meta, MetaType.Provenance)}
                                                />
                                                <Email metaField={metaFieldForMetaType(meta.Meta, MetaType.Email)} />
                                                <Phone metaField={metaFieldForMetaType(meta.Meta, MetaType.Phone)} />
                                                <Tags metaField={metaFieldForMetaType(meta.Meta, MetaType.Tags)} />
                                                <Custom metaField={metaFieldForMetaType(meta.Meta, MetaType.Custom)} />
                                            </Stack>
                                        )}
                                    </Stack>
                                )}
                                {cardType === CardType.General && (
                                    <Stack spacing={2} divider={<Divider />}>
                                        <Stack spacing={2}>
                                            <Title metaField={metaFieldForMetaType(meta.Meta, MetaType.Title)} />
                                            <Link metaField={metaFieldForMetaType(meta.Meta, MetaType.Link)} />
                                            <ProductLink
                                                metaField={metaFieldForMetaType(meta.Meta, MetaType.ProductLink)}
                                            />
                                        </Stack>
                                        {metaIncludes(meta.Meta, [
                                            MetaType.Description,
                                            MetaType.Tags,
                                            MetaType.Custom,
                                        ]) && (
                                            <Stack spacing={2}>
                                                <Description
                                                    metaField={metaFieldForMetaType(meta.Meta, MetaType.Description)}
                                                />
                                                <Tags metaField={metaFieldForMetaType(meta.Meta, MetaType.Tags)} />
                                                <Custom metaField={metaFieldForMetaType(meta.Meta, MetaType.Custom)} />
                                            </Stack>
                                        )}
                                    </Stack>
                                )}
                                {cardType === CardType.Contact && (
                                    <Stack spacing={2} divider={<Divider />}>
                                        <Stack spacing={2}>
                                            <Title metaField={metaFieldForMetaType(meta.Meta, MetaType.Title)} />
                                            <Company metaField={metaFieldForMetaType(meta.Meta, MetaType.Company)} />
                                            <JobTitle metaField={metaFieldForMetaType(meta.Meta, MetaType.JobTitle)} />
                                            <Link metaField={metaFieldForMetaType(meta.Meta, MetaType.Link)} />
                                        </Stack>
                                        {metaIncludes(meta.Meta, [MetaType.Custom]) && (
                                            <Stack spacing={2}>
                                                <Custom metaField={metaFieldForMetaType(meta.Meta, MetaType.Custom)} />
                                            </Stack>
                                        )}
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            )}
            <BulkUploader
                onStart={() => {
                    setIsProcessing(true);
                    // setTimeout(() => {
                    //     setIsProcessing(false);
                    // }, 3000);
                }}
                onComplete={() => {
                    setIsProcessing(false);
                }}
            />
            {image && (
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                    sx={{
                        alignItems: { xs: 'normal', md: 'flex-start' },
                    }}
                >
                    <Paper
                        sx={{
                            flex: 2,
                            p: 4,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White, // Color.LightLavenderLightMode,
                        }}
                        elevation={0}
                    >
                        <Stack direction="column" spacing={4}>
                            <Typography
                                sx={{
                                    fontFamily: 'Nocturne Serif',
                                    fontSize: '24px',
                                    fontWeight: 400,
                                    lineHeight: '26px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                }}
                            >
                                Similar Images
                            </Typography>
                            {isSimilarImagesLoading ?
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            : similarImages.length ?
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: isSmall ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)',
                                        gap: '10px',
                                    }}
                                >
                                    {[...similarImages].map(similarImage => {
                                        return (
                                            <img
                                                key={similarImage.imageID}
                                                src={similarImage.imageUrl}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: '8px',
                                                    objectFit: 'cover',
                                                    aspectRatio: '1 / 1',
                                                    cursor: 'pointer',
                                                }}
                                                alt=""
                                                onClick={() => {
                                                    if (image) {
                                                        navigateAndScrollToTop(
                                                            `/dashboard/ircodes/${similarImage.imageID}`,
                                                        );
                                                    }
                                                }}
                                            ></img>
                                        );
                                    })}
                                </div>
                            :   <Typography
                                    sx={{
                                        fontFamily: 'Nocturne Serif',
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                    }}
                                >
                                    None.
                                </Typography>
                            }
                        </Stack>
                    </Paper>
                    <Paper
                        sx={{
                            flex: 1,
                            p: 4,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                        }}
                        elevation={0}
                    >
                        <Stack direction="column" spacing={4}>
                            <Typography
                                sx={{
                                    fontFamily: 'Nocturne Serif',
                                    fontSize: '24px',
                                    fontWeight: 400,
                                    lineHeight: '26px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                }}
                            >
                                More by this artist
                            </Typography>
                            {isMoreByThisArtistLoading ?
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            : moreByThisArtist.length ?
                                <Stack direction="column" spacing={1}>
                                    {[...moreByThisArtist].map(image => {
                                        const metaFields = (image.metaArray ?? []).filter(
                                            metaField => metaField.metaType === MetaType.Title,
                                        );
                                        const title =
                                            metaFields.length > 0 ?
                                                (metaFields[0].metaContent as TTitle)?.title
                                            :   'Untitled';

                                        return (
                                            <Stack
                                                key={image.imageID}
                                                // direction="row"
                                                direction={{ xs: 'row', sm: 'column', lg: 'row' }}
                                                spacing={2}
                                                sx={{
                                                    alignItems: 'center',
                                                    borderRadius: 2,
                                                    overflow: 'hidden',
                                                    backgroundColor:
                                                        darkMode ?
                                                            Color.LightLavenderDarkMode
                                                        :   Color.LightLavenderLightMode,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (image) {
                                                        navigateAndScrollToTop(`/dashboard/ircodes/${image.imageID}`);
                                                    }
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        flexShrink: 0,
                                                        width: { xs: 100 },
                                                        height: { xs: 100 },
                                                        borderTopLeftRadius: '8px',
                                                        borderTopRightRadius: 0,
                                                        borderBottomLeftRadius: '8px',
                                                        borderBottomRightRadius: 0,
                                                        objectFit: 'cover',
                                                    }}
                                                    src={image.imageUrl}
                                                    alt=""
                                                />
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Nocturne Serif',
                                                        fontSize: '20px',
                                                        fontWeight: 400,
                                                        lineHeight: '1.4em',
                                                        letterSpacing: '0em',
                                                        textAlign: 'left',
                                                        pr: 2,
                                                        py: 2,
                                                        wordBreak: 'break-word',
                                                    }}
                                                >
                                                    {title}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                                </Stack>
                            :   <Typography
                                    sx={{
                                        fontFamily: 'Nocturne Serif',
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                    }}
                                >
                                    None.
                                </Typography>
                            }
                        </Stack>
                    </Paper>
                </Stack>
            )}
            {imageNotFound && (
                <Typography
                    variant="mainFont6"
                    sx={{
                        paddingY: 8,
                        textAlign: 'center',
                    }}
                >
                    Sorry, that IRCODE cannot be found.
                </Typography>
            )}
            {/* { isProcessing && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            )} */}
        </Stack>
    );
}
