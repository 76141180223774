import React, { useState, useContext, useEffect } from 'react';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import {
    Stack,
    TextField,
    InputAdornment,
    Button,
    Box,
    Typography,
    Tooltip,
    Dialog,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox,
    Select,
    SelectChangeEvent,
    FormLabel,
    Divider,
} from '@mui/material';
import { Color } from '../../Color';
import ModAdmin from './ModAdmin';
import EditUser from './EditUser';
import ModAccount from './ModAccount';
import EnvContext, { TEnv } from '../../contexts/EnvContext';
import RemoveUser from './RemoveUser';
import PushNotificationForm from './PushNotificationForm';
import PasswordResetEmail from './PasswordResetEmail';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';

enum BulkActionType {
    PushNotification = 'Push Notification',
    RemoveUsers = 'Remove Users',
    PasswordEmail = 'Password Email',
    UpgradeUsers = 'Upgrade Users',
    ModifyAdminType = 'Modify Admin Type',
    EditUsers = 'Edit Users',
}

export default function UserAnalytics() {
    const { impersonate } = useContext(EnvContext) as TEnv;
    const { fetchUserSearch, analyticsRefresh, sendPushNotification } = useContext(AdminContext) as TAdmin;
    const { notify } = useContext(FeedbackContext) as TFeedback;
    const [count, setCount] = useState(0);
    const [searchResults, setSearchResults] = useState<UserSearchResults[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [days, setDays] = useState('');
    const [excludeAnon, setExcludeAnon] = useState(true);
    const [sortField, setSortField] = useState<keyof UserSearchResults>('userCreated');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const [userCount, setUserCount] = useState(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [activeButtons, setActiveButtons] = useState<{ [key: number]: boolean }>({});
    const [openPasswordResetDialog, setOpenPasswordResetDialog] = useState(false);
    const [removeDialog, setRemoveDialog] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [openAdminModDialog, setOpenAdminModDialog] = useState(false);
    const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
    const [selectedUserType, setSelectedUserType] = useState('');
    const [selectedAdminType, setSelectAdminedType] = useState('');
    const [activeMenuItemIndex, setActiveMenuItemIndex] = useState<number | null>(null);
    const [value, setValue] = useState('');
    const [activeDialog, setActiveDialog] = useState<{ type: string; index: number } | null>(null);
    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UserSearchResults[]>([]);
    const [action, setAction] = useState('');
    const [openPushNotificationDialog, setOpenPushNotificationDialog] = useState(false);
    const [pushNotificationData, setPushNotificationData] = useState({
        selectedUsers: [] as UserSearchResults[],
        message: '',
        title: '',
        imageID: '',
        twoFACode: '',
    });
    const [showSelectedUsers, setShowSelectedUsers] = useState(false);

    // const { darkMode } = useContext(ThemeContext) as TTheme;

    useEffect(() => {
        const fetchData = async () => {
            setIsLocalLoading(true);
            const response = await fetchUserSearch(searchTerm, days, excludeAnon);
            if (response?.Results.Users) {
                const users = response.Results.Users;
                const sortedUsers = sortUsers(users, sortField, sortDirection);
                setSearchResults(sortedUsers);
                setCount(count + 1);
                setUserCount(sortedUsers.length);
            }
            setIsLocalLoading(false);
        };
        fetchData();
    }, [searchTerm, days, analyticsRefresh, sortField, sortDirection, excludeAnon]);

    const clearSearchTerm = () => {
        setSearchTerm('');
    };

    const clearDays = () => {
        setDays('');
    };

    const handleActionChange = (event: SelectChangeEvent<string>) => {
        const actionType = event.target.value as BulkActionType;
        setAction(actionType);

        switch (actionType) {
            case BulkActionType.PushNotification:
                if (selectedUsers.length > 0) {
                    setPushNotificationData(prevData => ({
                        ...prevData,
                        selectedUsers: [...selectedUsers],
                    }));
                    setOpenPushNotificationDialog(true);
                } else {
                    notify('No users selected for push notification.');
                    setAction('');
                }
                break;
            case BulkActionType.RemoveUsers:
                if (selectedUsers.length > 0) {
                    setRemoveDialog(true);
                } else {
                    notify('No users selected for removal.');
                    setAction('');
                }
                break;

            case BulkActionType.PasswordEmail:
                if (selectedUsers.length > 0) {
                    setOpenPasswordResetDialog(true);
                } else {
                    notify('No users selected');
                    setAction('');
                }
                break;

            case BulkActionType.UpgradeUsers:
                if (selectedUsers.length > 0) {
                    setOpenUpgradeDialog(true);
                } else {
                    notify('No users selected');
                    setAction('');
                }
                break;
            case BulkActionType.ModifyAdminType:
                if (selectedUsers.length > 0) {
                    setOpenAdminModDialog(true);
                } else {
                    notify('No users selected');
                    setAction('');
                }
                break;
            case BulkActionType.EditUsers:
                if (selectedUsers.length > 0) {
                    setOpenEditUserDialog(true);
                } else {
                    notify('No users selected');
                    setAction('');
                }
                break;
            default:
                setAction('');
                break;
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const effectiveDays = searchTerm.trim() === '' && days.trim() === '' ? '999' : days;
        const response = await fetchUserSearch(searchTerm, effectiveDays, excludeAnon);
        if (response?.Results.Users) {
            const users = response.Results.Users;
            setSearchResults(users);
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setActiveMenuItemIndex(index);
        setActiveButtons(prevState => ({
            ...prevState,
            [index]: true,
        }));
    };

    const handleMenuClose = (index: number) => {
        setActiveMenuItemIndex(null);
        setAnchorEl(null);
        setActiveButtons(prevState => ({
            ...Object.keys(prevState).reduce(
                (acc, key) => {
                    const numKey = Number(key);
                    acc[numKey] = false;
                    return acc;
                },
                {} as { [key: number]: boolean },
            ),
            [index]: false,
        }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleSort = (field: keyof UserSearchResults) => {
        const isSameField = sortField === field;
        const newSortDirection =
            isSameField ?
                sortDirection === 'asc' ?
                    'desc'
                :   'asc'
            :   'asc';

        setSortField(field);
        setSortDirection(newSortDirection);

        const sortedResults = sortUsers(searchResults, field, newSortDirection);
        setSearchResults(sortedResults);
    };
    const sortUsers = (users: UserSearchResults[], field: keyof UserSearchResults, direction: 'asc' | 'desc') => {
        return [...users].sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return direction === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string' && typeof bValue === 'string') {
                return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            return 0;
        });
    };
    const handleOpenDialog = (type: string, index: number) => {
        setActiveDialog({ type, index });
    };

    const handleCloseDialog = () => {
        setActiveDialog(null);
    };
    const handleCloseRemoveUserDialog = () => {
        resetSelectedUsers();
        setRemoveDialog(false);
    };

    const handleUserSelect = (user: UserSearchResults) => {
        const isAlreadySelected = selectedUserIds.includes(user.userID);

        setSelectedUserIds(prevIds =>
            isAlreadySelected ? prevIds.filter(id => id !== user.userID) : [...prevIds, user.userID],
        );

        setSelectedUsers(prevUsers =>
            isAlreadySelected ? prevUsers.filter(u => u.userID !== user.userID) : [...prevUsers, user],
        );
    };

    const resetSelectedUsers = () => {
        setSelectedUserIds([]);
        setSelectedUsers([]);
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flex: 1 }}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        m: 'auto',
                        p: 4,
                        borderRadius: '8px',
                        width: { sm: '100%', md: '90%' },
                        backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                        boxShadow: 1,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Stack direction="column" spacing={2} sx={{ marginBottom: 5 }}>
                            <form
                                onSubmit={handleSubmit}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <TextField
                                    placeholder="Search user by ..."
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Clear">
                                                    <Button onClick={clearSearchTerm}>
                                                        <i
                                                            style={{ fontWeight: 400 }}
                                                            className="fa-solid fa-xmark"
                                                        ></i>
                                                    </Button>
                                                </Tooltip>
                                                <TextField
                                                    variant="standard"
                                                    placeholder="Days"
                                                    value={days}
                                                    onChange={e => setDays(e.target.value)}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '0 10px',
                                                            width: '60px',
                                                            textAlign: 'center',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        endAdornment:
                                                            days ?
                                                                <InputAdornment position="start">
                                                                    <Typography>Days</Typography>
                                                                </InputAdornment>
                                                            :   null,
                                                    }}
                                                />
                                                <Tooltip title="Clear">
                                                    <Button onClick={clearDays}>
                                                        <i
                                                            style={{ fontWeight: 400 }}
                                                            className="fa-solid fa-xmark"
                                                        ></i>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Search">
                                                    <Button type="submit">
                                                        <i
                                                            style={{ fontWeight: 400, fontSize: '18px' }}
                                                            className="fa-thin fa-arrow-right"
                                                        ></i>
                                                    </Button>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            width: { xs: '100px', sm: '350px', md: '546px' },
                                            height: '3rem',
                                            borderRadius: '50px',
                                        },
                                    }}
                                />
                                <FormLabel sx={{ ml: 2 }}>
                                    <Checkbox checked={excludeAnon} onChange={() => setExcludeAnon(!excludeAnon)} />
                                    Hide 0 IRCODE anons
                                </FormLabel>
                            </form>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    height: '3rem',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="mainFont9">{userCount} users in search results</Typography>
                            </Box>
                        </Stack>
                    </Box>

                    <Stack
                        sx={{
                            background: 'inherit',
                            position: 'sticky',
                            top: 0,
                            zIndex: 5,
                            overflowY: 'auto',
                            height: showSelectedUsers ? '100vh' : 72,
                            transition: 'height 0.3s',
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                                background: 'inherit',
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                            }}
                        >
                            <Select
                                value={action}
                                onChange={handleActionChange}
                                displayEmpty
                                renderValue={currentAction =>
                                    currentAction === '' ? 'Bulk Operations' : currentAction
                                }
                            >
                                <MenuItem value="">Bulk Operations</MenuItem>
                                <MenuItem value={BulkActionType.PushNotification}>Push Notification</MenuItem>
                                <MenuItem value={BulkActionType.RemoveUsers}>Remove Users</MenuItem>
                                <MenuItem value={BulkActionType.PasswordEmail}>Password Reset Email</MenuItem>
                                <MenuItem value={BulkActionType.UpgradeUsers}>Upgrade Users</MenuItem>
                                <MenuItem value={BulkActionType.ModifyAdminType}>Add/Remove Admin/Support</MenuItem>
                                <MenuItem value={BulkActionType.EditUsers}>Edit Users</MenuItem>
                            </Select>

                            <Button
                                onClick={() => {
                                    setShowSelectedUsers(!showSelectedUsers);
                                }}
                                disabled={selectedUsers.length === 0 && !showSelectedUsers}
                            >
                                {showSelectedUsers ? 'Return to Search' : 'Show Selected Users'}
                            </Button>
                            <Typography variant="mainFont9">
                                {selectedUsers.length} user{selectedUsers.length === 1 ? '' : 's'} selected
                            </Typography>
                        </Stack>

                        <TableContainer>
                            <Table sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Username</TableCell>
                                        <TableCell>Full Name</TableCell>
                                        <TableCell>Registered IRCODES</TableCell>
                                        <TableCell>Signed Up</TableCell>
                                        <TableCell>Last Active</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Account</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedUsers.map(user => (
                                        <TableRow key={user.userID}>
                                            <TableCell>
                                                <Checkbox checked={true} onClick={() => handleUserSelect(user)} />
                                            </TableCell>
                                            <TableCell>{user.userName}</TableCell>
                                            <TableCell>{user.fullName}</TableCell>
                                            <TableCell>{user.countIRCodes}</TableCell>
                                            <TableCell>
                                                {new Date(user.userCreated * 1000).toLocaleString(undefined, {
                                                    dateStyle: 'medium',
                                                })}
                                                <br />
                                                {new Date(user.userCreated * 1000).toLocaleString(undefined, {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hourCycle: 'h12',
                                                })}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(user.lastActive * 1000).toLocaleString(undefined, {
                                                    dateStyle: 'medium',
                                                })}
                                                <br />
                                                {new Date(user.lastActive * 1000).toLocaleString(undefined, {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hourCycle: 'h12',
                                                })}
                                            </TableCell>
                                            <TableCell>{user.phone}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.userTypeDisplay}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>

                    <TableContainer>
                        <Table sx={{ width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'userName'}
                                            direction={sortField === 'userName' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('userName')}
                                        >
                                            Username
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'fullName'}
                                            direction={sortField === 'fullName' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('fullName')}
                                        >
                                            Full Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'countIRCodes'}
                                            direction={sortField === 'countIRCodes' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('countIRCodes')}
                                        >
                                            Registered IRCODES
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'userCreated'}
                                            direction={sortField === 'userCreated' ? sortDirection : 'desc'}
                                            onClick={() => handleSort('userCreated')}
                                        >
                                            Signed Up
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'lastActive'}
                                            direction={sortField === 'lastActive' ? sortDirection : 'desc'}
                                            onClick={() => handleSort('lastActive')}
                                        >
                                            Last Active
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'phone'}
                                            direction={sortField === 'phone' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('phone')}
                                        >
                                            Phone
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'email'}
                                            direction={sortField === 'email' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('email')}
                                        >
                                            Email
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'userTypeDisplay'}
                                            direction={sortField === 'userTypeDisplay' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('userTypeDisplay')}
                                        >
                                            Account
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchResults.map((result, index) => (
                                    <TableRow key={result.userID}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedUserIds.includes(result.userID)}
                                                onClick={() => handleUserSelect(result)}
                                            />
                                        </TableCell>
                                        <TableCell
                                            onClick={() => window.open(`/dashboard/profile/${result.userID}`)}
                                            sx={{ alignItems: 'center' }}
                                        >
                                            <Typography
                                                sx={{
                                                    display: result.accountLocked ? 'inline' : 'none',
                                                    marginRight: 1,
                                                }}
                                            >
                                                <i className="fa-light fa-lock"></i>
                                            </Typography>
                                            {result.userName}
                                        </TableCell>
                                        <TableCell>{result.fullName}</TableCell>
                                        <TableCell>{result.countIRCodes}</TableCell>
                                        <TableCell>
                                            {new Date(result.userCreated * 1000).toLocaleString(undefined, {
                                                dateStyle: 'medium',
                                            })}
                                            <br />
                                            {new Date(result.userCreated * 1000).toLocaleString(undefined, {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hourCycle: 'h12',
                                            })}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(result.lastActive * 1000).toLocaleString(undefined, {
                                                dateStyle: 'medium',
                                            })}
                                            <br />
                                            {new Date(result.lastActive * 1000).toLocaleString(undefined, {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hourCycle: 'h12',
                                            })}
                                        </TableCell>
                                        <TableCell>{result.phone}</TableCell>
                                        <TableCell>{result.email}</TableCell>
                                        <TableCell>{result.userTypeDisplay}</TableCell>
                                        <TableCell>
                                            <Button variant="editIawUser" onClick={e => handleMenuClick(e, index)}>
                                                Action
                                                <i
                                                    style={{ marginLeft: '8px' }}
                                                    className={`fa-sharp fa-light ${activeButtons[index] ? 'fa-caret-down' : 'fa-caret-up'}`}
                                                ></i>
                                            </Button>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open && activeMenuItemIndex === index}
                                                onClose={() => handleMenuClose(index)}
                                                elevation={0}
                                                sx={{
                                                    transition: 'none',
                                                    animation: 'none',
                                                    boxShadow: 'none',
                                                    '& .MuiPaper-root': {
                                                        border: '1px solid purple',
                                                        borderRadius: '8px',
                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    key={`${index}-upgrade`}
                                                    onClick={event => {
                                                        setSelectedUsers([result]);
                                                        handleOpenDialog('upgrade', index);
                                                        handleMenuClose(index);
                                                        setOpenUpgradeDialog(true);
                                                        handleMenuClose(index);
                                                        setOpenUpgradeDialog(true);
                                                    }}
                                                >
                                                    Upgrade User
                                                </MenuItem>
                                                <MenuItem
                                                    key={`${index}-edit`}
                                                    onClick={event => {
                                                        setSelectedUsers([result]);
                                                        handleOpenDialog('edit', index);
                                                        setOpenEditUserDialog(true);
                                                        handleMenuClose(index);
                                                    }}
                                                >
                                                    Edit User Info
                                                </MenuItem>
                                                <MenuItem
                                                    key={`${index}-reset`}
                                                    onClick={event => {
                                                        setSelectedUsers([result]);
                                                        handleOpenDialog('reset', index);
                                                        setOpenPasswordResetDialog(true);
                                                        handleMenuClose(index);
                                                    }}
                                                >
                                                    Reset Password
                                                </MenuItem>

                                                <MenuItem
                                                    key={`${index}-admin`}
                                                    onClick={event => {
                                                        setSelectedUserType(
                                                            result.internalAdmin === 2 ? 'admin'
                                                            : result.internalAdmin === 3 ? 'support'
                                                            : 'none',
                                                        );
                                                        setSelectedUsers([result]);
                                                        setSelectAdminedType(
                                                            result.internalAdmin === 2 ? 'admin'
                                                            : result.internalAdmin === 3 ? 'support'
                                                            : 'none',
                                                        );
                                                        handleOpenDialog('admin', index);
                                                        handleCloseDialog();
                                                        handleMenuClose(index);
                                                        setOpenAdminModDialog(true);
                                                    }}
                                                >
                                                    Add/Remove Admin/Support
                                                </MenuItem>
                                                <MenuItem
                                                    key={`${index}-impersonate`}
                                                    onClick={event => {
                                                        impersonate({
                                                            // result as User
                                                            ...result,
                                                        });
                                                    }}
                                                >
                                                    Impersonate
                                                </MenuItem>
                                                <MenuItem
                                                    key={`${index}-remove`}
                                                    onClick={event => {
                                                        setSelectedUsers([result]);

                                                        setRemoveDialog(true);
                                                        handleMenuClose(index);
                                                    }}
                                                >
                                                    Mark to Remove
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>
            <Dialog open={openUpgradeDialog}>
                <ModAccount
                    value={value}
                    handleChange={handleChange}
                    setOpenAccountModDialog={setOpenUpgradeDialog}
                    selectedUsers={selectedUsers}
                    onClose={() => {
                        setOpenUpgradeDialog(false);
                        setAction('');
                        resetSelectedUsers();
                    }}
                />
            </Dialog>
            <PasswordResetEmail
                open={openPasswordResetDialog}
                onClose={() => {
                    setOpenPasswordResetDialog(false);
                    setAction('');
                    resetSelectedUsers();
                }}
                selectedUsers={selectedUsers}
            />
            <Dialog open={openAdminModDialog} onClose={handleCloseDialog}>
                <ModAdmin
                    handleAdminChange={handleChange}
                    setOpenAdminModDialog={setOpenAdminModDialog}
                    selectedUsers={selectedUsers}
                    onClose={() => {
                        setAction('');
                        setOpenAdminModDialog(false);
                        resetSelectedUsers();
                    }}
                />
            </Dialog>
            <Dialog open={openEditUserDialog} onClose={handleCloseDialog}>
                <EditUser
                    handleChange={handleChange}
                    setOpenEditUserDialog={setOpenEditUserDialog}
                    selectedUsers={selectedUsers}
                    onClose={() => {
                        setOpenEditUserDialog(false);
                        setAction('');
                        resetSelectedUsers();
                    }}
                />
            </Dialog>
            <Dialog open={removeDialog} onClose={handleCloseRemoveUserDialog}>
                <RemoveUser
                    usersToRemove={selectedUsers}
                    onClose={handleCloseRemoveUserDialog}
                    setRemoveDialog={setRemoveDialog}
                />
            </Dialog>
            <PushNotificationForm
                open={openPushNotificationDialog}
                onClose={() => {
                    setOpenPushNotificationDialog(false);
                    setAction('');
                }}
                sendPushNotification={sendPushNotification}
                selectedUsers={pushNotificationData.selectedUsers}
                resetSelectedUsers={resetSelectedUsers}
            />
        </>
    );
}
