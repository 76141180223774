import { AuthProviders } from '../contexts/UserContext';
import { PasswordValidState } from '../hooks/usePasswordValidator';
import { createStore } from 'zustand';

export interface TAccount {
    fullName: string;
    userName: string;
    email: string;
    phoneNumber: string;
    profileUrl: string | undefined;
    newPassword: string;
    confirmNewPassword: string;
    currentPassword: string;
    avatarFile: File | null | undefined;
    reauthenticationRequired?: boolean;
    authProviders: AuthProviders;
    passwordValidState: PasswordValidState;
    emailFieldDisabled: boolean;

    setInfo(info: Partial<TAccount>): void;
}

const initProfileInfo: Omit<TAccount, 'setInfo'> = {
    fullName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    profileUrl: '',
    newPassword: '',
    confirmNewPassword: '',
    currentPassword: '',
    avatarFile: null,
    authProviders: { password: false, google: false, apple: false },
    passwordValidState: {
        length: false,
        lowerCaseLetter: false,
        upperCaseLetter: false,
        number: false,
        specialCharacter: false,
    },
    emailFieldDisabled: false,
};

export const createProfileInfoStore = () => {
    return createStore<TAccount>()(set => ({
        ...initProfileInfo,
        setInfo(info) {
            set(info);
        },
    }));
};
