import { Stack, Typography } from '@mui/material';
import { useState, useCallback, useEffect, useContext } from 'react';
import MediaContext, { TMedia } from 'src/contexts/MediaContext';
import { Image } from '../../../../../types/Image';
import ResponsiveLineChart, { Datum, Serie } from './ResponsiveLineChart';
import useKeenAnalytics from 'src/hooks/analytics/useKeenAnalytics';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import EnvContext, { TEnv } from 'src/contexts/EnvContext';
import useIrdbAnalytics, { Stat } from 'src/hooks/analytics/useIrdbAnalytics';
import { Interval, Source, TimeframeAndInterval } from 'src/types/Analytics';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { DateTime } from 'luxon';
import { randomColor } from 'src/util/color';
import { Point } from '@nivo/line';

export interface ServerHit {
    // intervalStart: number;
    // intervalStartHuman: string;
    imageScanned: number;
    imageLinkOpened: number;
    users: number;

    // imageScanned: serverHit.imageScanned,
    // imageLinkOpened: serverHit.imageLinkOpened,
    // users: serverHit.users,
}

interface ActivityChartProps {
    source: Source;
    campaignID: number;
    // metric: Metric;
    timeframeAndInterval?: TimeframeAndInterval;

    // Keen
    // timeframe: Timeframe;
    // interval: Interval;

    // IRDB
    // days: number;
    // unit: string; // wanted to call this interval but it conflicts with the Keen interval
    start?: string;
    end?: string;

    // onMetricChange: (metric: Metric) => void;
}

export default function ActivityChart({
    source,
    campaignID,
    // metric,
    timeframeAndInterval,
    start,
    end,
    // onMetricChange,
}: ActivityChartProps) {
    // export interface Datum {
    //     x: string | number;
    //     y: number;
    //     [key: string]: any;
    // }
    // export interface Serie {
    //     id: string | number;
    //     data: readonly Datum[];
    //     [key: string]: any;
    // }
    // interface Props {
    //     data: Serie[];
    //     interval: Interval;
    // }

    interface Activity {
        // totalCount: number;
        // frequency: number;

        id: string;
        // image?: Image;
        // color: string;
        // totalViews: number;
        data: Datum[];
    }

    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const { fetchImageList } = useContext(MediaContext) as TMedia;

    const [activity, setActivity] = useState<Activity[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const [chartsLoaded, setChartsLoaded] = useState(false); // Add boolean state for charts loaded
    const { client } = useContext(EnvContext) as TEnv;

    const { query } = useKeenAnalytics();
    const fetchKeenAnalytics = useCallback(() => {
        console.log('TopMediaChart fetchKeenAnalytics', timeframeAndInterval, start, end);
    }, [timeframeAndInterval, start, end]);

    const { fetch } = useIrdbAnalytics();
    const fetchIrdbAnalytics = useCallback(() => {
        console.log('[ANALYTICS] ActivityChart fetchIrdbAnalytics', timeframeAndInterval, start, end);
        return new Promise<Activity[]>((resolve, reject) => {
            console.log('[ANALYTICS] ActivityChart fetchIrdbAnalytics fetching...');

            // If this is unreadable thank eslint
            const interval =
                timeframeAndInterval?.unit ? timeframeAndInterval?.unit
                : start && end ?
                    DateTime.fromISO(end).diff(DateTime.fromISO(start)).days <= 2 ?
                        'hours'
                    :   'days'
                :   'days';

            // https://us-central1-ircode-1a662.cloudfunctions.net/production-v1_40/Statistics/fetch?campaignid=68&stat=serverHits&start=2024-11-13T18:00:00.000-06:00&end=2024-11-13T21:00:00.000-06:00
            // https://us-central1-ircode-1a662.cloudfunctions.net/production-v1_40/Statistics/fetch?campaignid=68&stat=serverHits&start=2024-11-13T18:00:00.000-06:00&end=2024-11-13T21:00:00.000-06:00

            fetch<ServerHit>({
                campaignID,
                // metric: metric === Metric.Scans ? Metric.ScanStats : Metric.ViewStats,
                // metric,
                stat: Stat.ServerHits, // metric === Metric.Scans ? Stat.Scans : Stat.Views, //  'imageScanned' : 'imageViewed',
                // timeframe: timeframeAndInterval?.days,
                // interval,
                intervalHits: 600,
                // intervalHits: 300,
                // intervalHits: 60,
                start,
                end,
                // TODO: The offset start end should be passed to the chart, not readjusted here
                // start: start ? (DateTime.fromISO(start).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
                // end: end ? (DateTime.fromISO(end).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
            })
                .then(async results => {
                    // console.log('[ANALYTICS] ActivityChart fetchIrdbAnalytics results', results);

                    const formattedResults = results.reduce(
                        (acc: any, result: any) => {
                            const x = DateTime.fromMillis(result.timeframe * 1000).toISO();

                            acc[0].data.push({
                                x,
                                y: result.value.imageLinkOpened,
                            });
                            acc[1].data.push({
                                x,
                                y: result.value.imageScanned,
                            });
                            // acc[2].data.push({
                            //     x,
                            //     y: result.value.users,
                            // });
                            return acc;
                        },
                        [
                            {
                                // id: 'imageLinkOpened',
                                id: 'Clickthroughs',
                                color: randomColor(),
                                data: [],
                            },
                            {
                                // id: 'imageScanned',
                                id: 'Scans',
                                color: randomColor(),
                                data: [],
                            },
                            // {
                            //     id: 'users',
                            //     color: randomColor(),
                            //     data: [],
                            // },
                        ],
                    );

                    // console.log('[ANALYTICS] AnalyticsChart fetchIrdbAnalytics formattedResults', formattedResults);
                    resolve(formattedResults);
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {
                    setChartsLoaded(true);
                });
        });
    }, [campaignID, timeframeAndInterval, start, end, fetch]);

    useEffect(() => {
        // console.log('TopMediaChart useEffect', campaignID, images);
        // if (!campaignID || !images || images.length === 0) {
        //     return;
        // }

        if (!campaignID) {
            return;
        }

        setChartsLoaded(false);

        // const fetchAnalytics = source === Source.Keen ? fetchKeenAnalytics : fetchIrdbAnalytics;
        const fetchAnalytics = fetchIrdbAnalytics;
        fetchAnalytics()
            .then(results => {
                setActivity(results);
            })
            .catch((error: any) => {
                console.error('error', error);
                setActivity([]);
            });
    }, [client, source, campaignID, images, fetchKeenAnalytics, fetchIrdbAnalytics]);

    return (
        <Stack direction="column">
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                }}
            >
                Scans vs Clickthroughs
            </Typography>

            {!chartsLoaded || !activity.length ?
                <Stack
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 300,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '1.2em',
                            fontWeight: 400,
                            lineHeight: '1.5em',
                            textAlign: 'center',
                        }}
                    >
                        {!chartsLoaded ?
                            'Loading...'
                        :   'There is currently no data available for this time period. Please try another view.'}
                    </Typography>
                </Stack>
            :   <ResponsiveLineChart
                    data={activity}
                    interval={timeframeAndInterval?.interval ?? Interval.Daily}
                    showLegend={true}
                    tooltip={(point: Point, serie: Serie) => {
                        // console.log('serie', serie);
                        // console.log('point.data.x', point.data.x);

                        const dateText = DateTime.fromISO(point.data.x as string, { setZone: true })
                            // .setZone(true)
                            // .zo
                            // .off
                            .toFormat('yyyy-MM-dd HH:mm:ss');

                        // const date = new Date(point.data.x);
                        // const dateText = date.toLocaleString('en-US', {
                        //     minute: 'numeric',
                        //     day: 'numeric',
                        //     month: 'long',
                        //     year: 'numeric',
                        // });

                        let action;
                        const singular = point.data.yFormatted === '1';
                        switch (serie.id) {
                            // case 'imageLinkOpened':
                            case 'Clickthroughs':
                                action = singular ? 'link opened' : 'links opened';
                                break;
                            // case 'imageScanned':
                            case 'Scans':
                                action = singular ? 'image scanned' : 'images scanned';
                                break;
                            case 'users':
                                action = singular ? 'user' : 'users';
                                break;
                            default:
                                action = '';
                        }

                        // const dateText =
                        //     isHourly ?
                        //         `${date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} at ${date.toLocaleString('en-US', { hour: 'numeric', hour12: true })}`
                        //     :   date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

                        return (
                            <>
                                <b>
                                    {point.data.yFormatted} {action}
                                </b>
                                &nbsp;on&nbsp;{dateText}
                            </>
                        );
                    }}
                />
            }
        </Stack>
    );
}
