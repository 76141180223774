import Image from 'src/types/Image';
import { MetaContent, MetaType } from 'src/types/MetaTypes';
import { useMemo } from 'react';
import { metaContentForMetaType } from '../../contexts/MetaContext';

function useImageMetaContent<T extends keyof typeof MetaType = keyof typeof MetaType>(
    image: Image | undefined,
    metaType: T,
): MetaContent<(typeof MetaType)[T]> | undefined {
    return useMemo(() => image && metaContentForMetaType(image, MetaType[metaType]), [image?.metaArray, metaType]);
}

export default useImageMetaContent;
