import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Box, Button, Drawer, Stack, Typography } from '@mui/material';
import { Color, purpleGradientCSS } from '../../../Color';
import UserContext, { TUser, User, UserType } from '../../../contexts/UserContext';
import { useContext, useState } from 'react';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import ProfileContextMenu from './ProfileContextMenu';
import UserAvatar from 'src/components/general/UserAvatar';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import EnvContext, { TEnv } from 'src/contexts/EnvContext';

const drawerWidth = 280;

const navItems = (user: User | undefined | null) => {
    return [
        ...(user?.userType === UserType.Enterprise ?
            [
                {
                    label: 'ENTERPRISE OVERVIEW',
                    icon: <i className="fa-fw fa-light fa-expand"></i>,
                    path: '/enterprise',
                    regex: /^\/enterprise\/?$/,
                },
                {
                    label: 'CAMPAIGNS',
                    icon: <i className="fa-fw fa-light fa-building"></i>,
                    path: '/enterprise/campaigns',
                    regex: /^\/enterprise\/campaigns\/?$/,
                },
            ]
        :   [
                {
                    label: 'OVERVIEW',
                    icon: <i className="fa-fw fa-light fa-expand"></i>,
                    path: '/dashboard',
                    regex: /^\/dashboard\/?$/,
                },
            ]),
        {
            label: 'MY IRCODES',
            icon: <i className="fa-fw fa-light fa-list"></i>,
            path: '/dashboard/myircodes',
            regex: /^\/dashboard\/myircodes\/?[\s\S]*?$/,
        },
        // {
        //     label: 'ANALYTICS',
        //     icon: <i className="fa-fw fa-light fa-chart-line"></i>,
        //     path: '/dashboard/analytics',
        //     regex: /^\/dashboard\/analytics\/?$/,
        // },
        {
            label: 'NOTIFICATIONS',
            icon: <i className="fa-fw fa-light fa-bell"></i>,
            path: '/dashboard/notifications',
            regex: /^\/dashboard\/notifications\/?$/,
        },
        {
            label: 'HISTORY',
            icon: <i className="fa-fw fa-light fa-clock-rotate-left"></i>,
            path: '/dashboard/history',
            regex: /^\/dashboard\/history\/?$/,
        },
        {
            label: 'TRASH',
            icon: <i className="fa-fw fa-light fa-trash"></i>,
            path: '/dashboard/trash',
            regex: /^\/dashboard\/trash\/?$/,
        },
        {
            label: 'SETTINGS',
            icon: <i className="fa-fw fa-light fa-gear"></i>,
            path: '/dashboard/settings',
            regex: /^\/dashboard\/settings\/?$/,
        },
        ...((user?.internalAdmin ?? 0 > 0) ?
            [
                {
                    label: 'INTERNAL ADMIN',
                    icon: <i className="fa-fw fa-light fa-cog"></i>,
                    path: '/iaw',
                },
            ]
        :   []),
        {
            label: 'IRCODE WEBSITE',
            icon: <i className="fa-fw fa-light fa-globe"></i>,
            path: 'https://ircode.com',
        },
    ];
};

export default function NavBar() {
    const location = useLocation();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();

    const { client } = useContext(EnvContext) as TEnv;
    const { isMobile, darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const openUrl = (url: string) => {
        if (url.startsWith('http')) {
            window.open(url, '_blank');
        } else {
            navigateAndScrollToTop(url);
        }
        setIsNavOpen(false);
    };

    const isEnterprise = user?.userType === UserType.Enterprise;
    const appStoreUrl = 'https://apps.apple.com/us/app/ircode/id6462335706';

    const ctaButtons = (
        <>
            {false && (
                <Button
                    sx={{
                        flexDirection: 'column',
                        alignContent: 'flex-start',
                        justifyContent: 'space-between',
                        width: 200,
                        height: 120,
                        p: 2,
                        borderRadius: 2,
                        background: purpleGradientCSS,
                    }}
                >
                    <span style={{ alignSelf: 'flex-start' }}>
                        <i className="fa-fw fa-regular fa-gem fa-2xl" style={{ color: Color.White }}></i>
                    </span>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            textTransform: 'none',
                            color: Color.White,
                        }}
                    >
                        Upgrade your account
                    </Typography>
                </Button>
            )}

            <Button
                sx={{
                    flexDirection: 'column',
                    alignContent: 'flex-start',
                    justifyContent: 'space-between',
                    width: 200,
                    height: 120,
                    p: 2,
                    borderRadius: 2,
                    background: Color.LightLavenderLightMode,
                    '&:hover': {
                        background: Color.LightLavenderDarkMode,
                    },
                }}
                onClick={() => {
                    window.open(appStoreUrl, '_blank');
                }}
            >
                <span style={{ alignSelf: 'flex-start' }}>
                    <i className="fa-fw fa-regular fa-sparkles fa-2xl" style={{ color: Color.White }}></i>
                </span>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        textTransform: 'none',
                        color: Color.White,
                    }}
                >
                    Download the IRCODE app now
                </Typography>
            </Button>
        </>
    );

    if (isMobile) {
        return (
            <>
                <Stack
                    direction="row"
                    spacing={0}
                    sx={{
                        height: 84,
                        backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderLightMode,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexBasis: 80,
                            flexShrink: 0,
                            justifyContent: 'center', // horizontal
                            alignItems: 'center', // vertical
                        }}
                    >
                        <i className="fa-fw fa-solid fa-bars fa-2xl" onClick={() => setIsNavOpen(true)}></i>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'center', // horizontal
                            alignItems: 'center', // vertical
                        }}
                    >
                        <Box
                            id="DashboardLogoAdaptive"
                            className={[isEnterprise ? 'enterprise' : 'pro', darkMode ? 'dark' : 'light'].join(' ')}
                            component="img"
                            sx={{
                                objectFit: 'contain',
                                width: 'auto',
                                height: '26px',
                            }}
                            alt=""
                            onClick={() => openUrl('/dashboard')}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexBasis: 80,
                            flexShrink: 0,
                            justifyContent: 'center', // horizontal
                            alignItems: 'center', // vertical
                        }}
                    >
                        <UserAvatar
                            profileUrl={user?.profileUrl}
                            userName={user?.userName}
                            onClick={event => {
                                setAnchorEl(event.currentTarget);
                            }}
                        />
                    </Box>
                    <ProfileContextMenu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        handleClose={() => {
                            setAnchorEl(null);
                        }}
                    />
                </Stack>
                <Drawer
                    variant="temporary"
                    open={isNavOpen}
                    onClose={() => setIsNavOpen(false)}
                    transitionDuration={0}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <Stack
                        component="nav"
                        sx={{
                            backgroundColor: '#121219',
                            width: '100vw',
                            height: '100vh',
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={0}
                            sx={{
                                height: 84,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexBasis: 80,
                                    flexShrink: 0,
                                    justifyContent: 'center', // horizontal
                                    alignItems: 'center', // vertical
                                }}
                            >
                                <i
                                    className="fa-fw fa-solid fa-xmark fa-2xl"
                                    style={{ color: Color.White }}
                                    onClick={() => setIsNavOpen(false)}
                                ></i>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    justifyContent: 'center', // horizontal
                                    alignItems: 'center', // vertical
                                }}
                            >
                                <Box
                                    id="DashboardLogoWhite"
                                    className={isEnterprise ? 'enterprise' : 'pro'}
                                    component="img"
                                    sx={{
                                        objectFit: 'contain',
                                        width: 'auto',
                                        height: '26px',
                                    }}
                                    alt=""
                                    onClick={() => openUrl('/dashboard')}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexBasis: 80,
                                    flexShrink: 0,
                                }}
                            />
                        </Stack>

                        <Stack
                            sx={{
                                alignItems: 'stretch',
                            }}
                        >
                            {navItems(user).map(item => (
                                <Button
                                    key={item.label}
                                    sx={{
                                        justifyContent: 'flex-start',
                                        paddingX: '40px',
                                        paddingY: '16px',
                                        borderRadius: 0,
                                        background:
                                            item.regex && location.pathname.match(item.regex) ?
                                                purpleGradientCSS
                                            :   'transparent',
                                    }}
                                    onClick={() => {
                                        openUrl(item.path);
                                    }}
                                >
                                    <Typography variant="webNavlink">
                                        <span style={{ paddingRight: '.5rem' }}>{item.icon}</span>
                                        {item.label}
                                    </Typography>
                                </Button>
                            ))}
                        </Stack>

                        <Box
                            sx={{
                                minHeight: 120,
                                flexGrow: 1,
                            }}
                        ></Box>

                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                // paddingX: '40px',
                                p: 5,
                            }}
                        >
                            {ctaButtons}
                        </Stack>
                    </Stack>
                </Drawer>
            </>
        );
    } else {
        return (
            <Stack
                component="nav"
                sx={{
                    width: drawerWidth,
                    paddingBottom: 4,
                    // TODO: Move to Color.ts
                    backgroundColor: '#121219',
                    height: '100vh',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    overflowY: 'auto',
                    flex: '0 0 auto',
                }}
            >
                <Stack sx={{ minHeight: '100%' }}>
                    <Box
                        id="DashboardLogoWhite"
                        className={isEnterprise ? 'enterprise' : 'pro'}
                        component="img"
                        sx={{
                            px: '40px',
                            pt: '40px',
                            pb: isEnterprise ? '20px' : '40px',
                            objectFit: 'contain',
                        }}
                        alt=""
                        onClick={() => openUrl('/dashboard')}
                    />
                    <Stack
                        sx={{
                            alignItems: 'stretch',
                            flexGrow: 1,
                        }}
                    >
                        {navItems(user).map(item => (
                            <Button
                                key={item.label}
                                sx={{
                                    justifyContent: 'flex-start',
                                    padding: '16px 0 16px 40px',
                                    borderRadius: 0,
                                    background:
                                        item.regex && location.pathname.match(item.regex) ?
                                            purpleGradientCSS
                                        :   'transparent',
                                    ...(item.label === 'NOTIFICATIONS' && {
                                        mb: 5,
                                    }),
                                    ...(item.label === 'HISTORY' && {
                                        mt: 'auto',
                                    }),
                                }}
                                onClick={() => {
                                    openUrl(item.path);
                                    if (item.label === 'NOTIFICATIONS' && user) {
                                        user.countUnRead = 0;
                                    }
                                }}
                            >
                                <Typography variant="webNavlink">
                                    <Badge
                                        color="error"
                                        variant="dot"
                                        invisible={item.label !== 'NOTIFICATIONS' || !user?.countUnRead}
                                        sx={{ mr: 1, zIndex: 0 }}
                                    >
                                        {item.icon}
                                    </Badge>
                                    {item.label}
                                </Typography>
                            </Button>
                        ))}
                    </Stack>

                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            mt: 2,
                            px: 5,
                            pb: 2,
                        }}
                    >
                        {ctaButtons}
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}
