import { useCallback, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Color } from 'src/Color';
import { nullUndefinedOrEmpty } from 'src/util/string';
import { Event, Source, Timeframe } from 'src/types/Analytics';
import { Parameter, QueryResult } from 'src/hooks/analytics/useAnalytics';
import useKeenAnalytics, { Type } from 'src/hooks/analytics/useKeenAnalytics';
import useIrdbAnalytics, { Stat } from 'src/hooks/analytics/useIrdbAnalytics';
import SortableTable from '../../../../general/SortableTable';

interface Props {
    source: Source;
    campaignID: number;
    timeframe?: Timeframe;
    start?: string;
    end?: string;
}

export default function ClickChart({ source, campaignID, timeframe, start, end }: Props) {
    interface Click {
        link_title: string;
        link_url: string;
        result: number;
    }

    interface Data {
        [key: string]: any;
    }

    const [clicks, setClicks] = useState<Click[]>([]);

    const { query } = useKeenAnalytics();
    const fetchKeenAnalytics = useCallback(() => {
        return new Promise<Click[]>((resolve, reject) => {
            query(
                // Type.CountUnique,
                Type.Count,
                {
                    event_collection: Event.ImageLinkOpened,
                    timeframe,
                    filters: [
                        {
                            property_name: Parameter.CampaignID,
                            operator: 'eq',
                            property_value: campaignID,
                        },
                    ],
                    group_by: ['link_title', 'link_url'],
                },
            )
                .then((results: QueryResult<any>[]) => {
                    console.log('ClickChart fetchKeenAnalytics results', results);
                    resolve(
                        results
                            .map((click: any, index: number) => {
                                if (!nullUndefinedOrEmpty(click.link_url) && !/^https?:\/\//i.test(click.link_url)) {
                                    click.link_url = 'https://' + click.link_url;
                                }
                                return {
                                    ...click,
                                    link_title:
                                        click.link_title.replace('&nbsp;', ' ') ||
                                        new URL(click.link_url).hostname.replace(/^www\./, ''),
                                };
                            })
                            .sort((a: Click, b: Click) => b.result - a.result),
                    );
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, query, timeframe]);

    const { fetch } = useIrdbAnalytics();
    const fetchIrdbAnalytics = useCallback(() => {
        // console.log('ClickChart fetchIrdbAnalytics', timeframeAndInterval, start, end);
        // https://us-central1-ircode-1a662.cloudfunctions.net
        // /production-v1_39/Statistics/fetch?
        // campaignid=68&stat=imageLinkOpened&timeframe=1&interval=hours

        // https://us-central1-ircode-1a662.cloudfunctions.net/production-v1_40/Statistics/fetch?campaignid=68&stat=mostScans&start=2024-11-13T18:00:00.000-06:00&end=2024-11-13T21:00:00.000-06:00&category=productPackaging

        return new Promise<any[]>((resolve, reject) => {
            console.log('fetching...');
            fetch<any[]>({
                campaignID,
                // metric: Metric.ImageLinkOpened,
                stat: Stat.ImageLinkOpened,
                // timeframe: timeframeAndInterval?.days,
                // timeframe: 1,
                // interval: timeframeAndInterval?.unit ?? 'days',
                interval: 'hours',
                start,
                end,
            }).then(async results => {
                console.log('[ANALYTICS] ClickChart fetchIrdbAnalytics results', results);
                resolve(
                    results
                        .map((click: any, index: number) => {
                            if (
                                !nullUndefinedOrEmpty(click.linkToFollow) &&
                                !/^https?:\/\//i.test(click.linkToFollow)
                            ) {
                                click.link_url = 'https://' + click.linkToFollow;
                            } else {
                                click.link_url = click.linkToFollow;
                            }
                            return {
                                ...click,
                                link_title:
                                    click.title.replace('&nbsp;', ' ') ||
                                    new URL(click.link_url).hostname.replace(/^www\./, ''),
                                result: click.count,
                            };
                        })
                        .sort((a: Click, b: Click) => b.result - a.result),
                );
            });
        });
    }, [campaignID, fetch]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        const fetchAnalytics = source === Source.Keen ? fetchKeenAnalytics : fetchIrdbAnalytics;
        fetchAnalytics()
            .then(newClicks => {
                setClicks(newClicks);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [source, campaignID, fetchKeenAnalytics, fetchIrdbAnalytics]);

    const columns = [
        { label: 'Links', sortValue: 'link_title' },
        { label: 'Clicks', sortValue: 'result' },
    ];

    const customCellRenderers: { [key: string]: (row: Data) => React.ReactNode } = {
        Links: row => (
            <span
                style={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                onClick={() => {
                    window.open(row.link_url, '_blank');
                }}
            >
                {row.link_title}&nbsp;
                <i
                    className="fa-regular fa-arrow-up-right-from-square"
                    style={{
                        color: Color.Purple,
                    }}
                />
            </span>
        ),
        Clicks: row => row.result,
    };

    return (
        <Stack
            direction="column"
            sx={
                {
                    // backgroundColor: Color.LightLavenderDarkMode
                }
            }
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                    mb: 2,
                }}
            >
                Clicks
            </Typography>

            <SortableTable columns={columns} rows={clicks} cellRenderers={customCellRenderers} overflowCount={4} />
        </Stack>
    );
}
