import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Footer from '../tools/Footer';
import { useContext, useEffect, useState } from 'react';
import { Color } from '../../../Color';
import MediaContext, { TMedia } from '../../../contexts/MediaContext';
import { Image } from '../../../types/Image';
import { useSearchParams } from 'react-router-dom';
import { MetaType } from '../../../types/MetaTypes';
import { metaContentForMetaType } from '../../../contexts/MetaContext';

export default function Contest() {
    const [searchParams] = useSearchParams();
    const { fetchContest } = useContext(MediaContext) as TMedia;

    const [isLoading, setIsLoading] = useState(true);
    const [contest, setContest] = useState<Image[]>();

    useEffect(() => {
        console.log('Contest mounting');
        setIsLoading(true);
        const from = parseInt(searchParams.get('from') ?? '1714147200'); // Friday, April 26, 2024 11:00:00 AM GMT-05:00 DST
        const to = parseInt(searchParams.get('to') ?? '1714341600'); // Sunday, April 28, 2024 5:00:00 PM GMT-05:00 DST

        fetchContest(from, to)
            .then(contest => {
                setContest(contest.slice(0, 10));
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {
            console.log('Contest unmounting');
        };
    }, []);

    // console.log('user', user);
    return (
        <Stack
            sx={{
                alignItems: 'center',

                backgroundColor: Color.Black,
                backgroundImage: {
                    xs: 'url(/images/contestBackground.png)',
                },
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top right',
            }}
        >
            <Stack
                direction="column"
                sx={{
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        // height: 300,
                        marginY: 12,
                        // maxWidth: '80%',
                        maxWidth: { xs: '90%', sm: '80%' },
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontSize: '64px',
                            fontWeight: 600,
                            lineHeight: '76.8px',
                            letterSpacing: '-0.03em',
                            color: Color.PalePurple,
                        }}
                    >
                        One of a Kind Festival
                    </Typography>
                    <Typography
                        component="span"
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontSize: '48px',
                            fontWeight: 600,
                            lineHeight: '76.8px',
                            letterSpacing: '-0.03em',
                            color: Color.White,
                        }}
                    >
                        Scan Contest
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '1rem',
                            fontFamily: 'Nunito Sans',
                            fontWeight: 400,
                            wordWrap: 'break-word',
                            color: Color.White,
                        }}
                    >
                        April 26-28 2024 | Highest scans wins
                    </Typography>
                </Box>
                {isLoading && (
                    <CircularProgress
                        sx={{
                            marginY: 12,
                            color: Color.PalePurple,
                        }}
                    />
                )}
                {(contest ?? []).length === 0 && (
                    <Typography
                        sx={{
                            marginY: 12,
                            fontSize: '2rem',
                            fontFamily: 'Nunito Sans',
                            fontWeight: 400,
                            wordWrap: 'break-word',
                            color: Color.White,
                        }}
                    >
                        Come back later to see the top scans!
                    </Typography>
                )}
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{
                        flexWrap: 'wrap',
                    }}
                >
                    {(contest ?? []).map((image, index) => {
                        return (
                            <Box
                                key={image.imageID}
                                sx={{
                                    width: { xs: '100%', md: '50%' },
                                    p: 2,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        backgroundImage: `linear-gradient(180deg, #1A1A22 0%, #00000000 100%)`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: 64,
                                            fontFamily: 'Nocturne Serif',
                                            fontSize: '64px',
                                            fontWeight: 600,
                                            lineHeight: '76.8px',
                                            textAlign: 'right',
                                            color: Color.PalePurple,
                                        }}
                                    >
                                        {index + 1}
                                    </Typography>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <Box
                                            component="img"
                                            src={image.imageUrl}
                                            sx={{
                                                display: 'flex',
                                                flexShrink: 0,
                                                width: { xs: 200, md: 100, lg: 200 },
                                                height: { xs: 200, md: 100, lg: 200 },
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <Stack
                                            direction="column"
                                            spacing={0.5}
                                            sx={{
                                                p: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '1rem',
                                                    fontFamily: 'Nunito Sans',
                                                    fontWeight: 400,
                                                    wordWrap: 'break-word',
                                                    color: Color.White,
                                                }}
                                            >
                                                {metaContentForMetaType(image, MetaType.ArtistName)?.name ??
                                                    'Unknown Artist'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '0.825rem',
                                                    fontFamily: 'Nunito Sans',
                                                    fontWeight: 400,
                                                    wordWrap: 'break-word',
                                                    color: Color.White,
                                                }}
                                            >
                                                {metaContentForMetaType(image, MetaType.Title)?.title || 'Untitled'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '0.825rem',
                                                    fontFamily: 'Nunito Sans',
                                                    fontWeight: 400,
                                                    textTransform: 'uppercase',
                                                    wordWrap: 'break-word',
                                                    color: Color.Purple,
                                                }}
                                            >
                                                {image.countQueries} scan{image.countQueries === 1 ? '' : 's'}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        );
                    })}
                </Stack>
            </Stack>
            <Footer />
        </Stack>
    );
}
