import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import MediaContext, { TMedia } from 'src/contexts/MediaContext';
import { Image } from '../../../../../types/Image';
import { randomColor } from 'src/util/color';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import EnvContext, { TEnv } from 'src/contexts/EnvContext';
import useIrdbAnalytics, { Stat } from 'src/hooks/analytics/useIrdbAnalytics';
import { Event, Metric, Source, TimeframeAndInterval } from 'src/types/Analytics';
import { Color } from 'src/Color';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { metaContentForMetaType, newMetaField } from '../../../../../contexts/MetaContext';
import { MetaType } from 'src/types/MetaTypes';
import { DateTime } from 'luxon';
import useKeenAnalytics, { Parameter, Type } from 'src/hooks/analytics/useKeenAnalytics';
import { QueryResult } from 'src/hooks/analytics/useAnalytics';

interface TopMediaChartProps {
    source: Source;
    campaignID: number;
    metric: Metric;
    timeframeAndInterval?: TimeframeAndInterval;

    // Keen
    // timeframe: Timeframe;
    // interval: Interval;

    // IRDB
    // days: number;
    // unit: string; // wanted to call this interval but it conflicts with the Keen interval
    start?: string;
    end?: string;

    onMetricChange: (metric: Metric) => void;
}

export default function TopMediaChart({
    source,
    campaignID,
    metric,
    timeframeAndInterval,
    start,
    end,
    onMetricChange,
}: TopMediaChartProps) {
    interface TopMedia {
        id: string;
        image?: Image;
        color: string;
        totalViews: number;
        data: any[];
    }

    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const { fetchImageList } = useContext(MediaContext) as TMedia;

    const [topMedia, setTopMedia] = useState<TopMedia[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const [chartsLoaded, setChartsLoaded] = useState(false); // Add boolean state for charts loaded
    const { client } = useContext(EnvContext) as TEnv;

    interface ImageViewsPerInterval {
        // Keen
        image_id: string;
        result: number;
        // IRCODE
        imageID: string;
        count: number;
    }

    const { query } = useKeenAnalytics();
    const fetchKeenAnalytics = useCallback(() => {
        console.log('TopMediaChart fetchKeenAnalytics', timeframeAndInterval, start, end);
        return new Promise<any[]>((resolve, reject) => {
            query<ImageViewsPerInterval[]>(
                // Type.CountUnique,
                Type.Count,
                {
                    event_collection: Event.ImageViewed,
                    timeframe: timeframeAndInterval?.timeframe,
                    interval: timeframeAndInterval?.interval,
                    filters: [
                        {
                            property_name: Parameter.CampaignID,
                            operator: 'eq',
                            property_value: campaignID,
                        },
                    ],
                    group_by: ['image_id'],
                },
            )
                .then(async results => {
                    console.log('Raw results', results);

                    // Backfill results for images with days with no views
                    // const image_ids = images.map(image => image.imageID);
                    // results = results
                    //     .map((result: QueryResult<ImageViewsPerInterval[]>, index: number) => {
                    //         for (const image_id of image_ids) {
                    //             if (!(result.value as ImageViewsPerInterval[]).find(r => r.image_id === image_id)) {
                    //                 (result.value as ImageViewsPerInterval[]).push({
                    //                     image_id,
                    //                     result: 0,
                    //                 });
                    //             }
                    //         }

                    //         return result;
                    //     });
                    // console.log('Backfilled results', results);

                    // Intermediate step for pivoting data
                    const resultsByImage = results.reduce(
                        (acc: any, result: QueryResult<ImageViewsPerInterval[]>) => {
                            for (const image of result.value as ImageViewsPerInterval[]) {
                                if (!acc[image.image_id]) {
                                    acc[image.image_id] = [];
                                }
                                acc[image.image_id].push({
                                    // @ts-ignore
                                    timeframe: result.timeframe?.start ?? '',
                                    result: image.result,
                                });
                            }

                            return acc;
                        },
                        {} as Record<string, ImageViewsPerInterval[]>,
                    );
                    // console.log('resultsByImage', resultsByImage);

                    let formattedResults: TopMedia[] = Object.keys(resultsByImage)
                        .map((image_id: string): TopMedia => {
                            return {
                                id: image_id,
                                color: randomColor(image_id),
                                totalViews: resultsByImage[image_id].reduce((acc: number, r: any) => acc + r.result, 0),
                                data: resultsByImage[image_id].map((r: any, index: number) => {
                                    return {
                                        x: r.timeframe,
                                        y: r.result,
                                    };
                                }),
                            };
                        })
                        .filter((media: TopMedia) => media.totalViews > 0)
                        .sort((a: TopMedia, b: TopMedia) => b.totalViews - a.totalViews);
                    // console.log('formattedResults', formattedResults);

                    const imageList = await fetchImageList(formattedResults.map((media: TopMedia) => media.id));
                    formattedResults = formattedResults
                        .filter(media => imageList.some(image => image.imageID === media.id))
                        .map((media: TopMedia) => {
                            const image = imageList.find(image => image.imageID === media.id) as Image;
                            const titleFieldIndex = image.metaArray.findIndex(meta => meta.metaType === MetaType.Title);
                            if (titleFieldIndex !== -1 && !image.metaArray[titleFieldIndex].metaContent.title) {
                                image.metaArray[titleFieldIndex].metaContent = { title: 'Untitled' };
                            } else {
                                image.metaArray.push(newMetaField(MetaType.Title, 'Untitled'));
                            }
                            media.image = image;
                            return media;
                        })
                        .slice(0, 6);

                    console.log('[ANALYTICS] TopMediaChart fetchKeenAnalytics formattedResults', formattedResults);
                    resolve(formattedResults);
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {
                    setChartsLoaded(true);
                });
        });
    }, [campaignID, timeframeAndInterval, start, end, query, fetchImageList]);

    const { fetch } = useIrdbAnalytics();
    const fetchIrdbAnalytics = useCallback(() => {
        console.log('TopMediaChart fetchIrdbAnalytics', timeframeAndInterval, start, end);
        return new Promise<any[]>((resolve, reject) => {
            console.log('TopMediaChart fetchIrdbAnalytics fetching...');

            // If this is unreadable thank eslint
            const interval =
                timeframeAndInterval?.unit ? timeframeAndInterval?.unit
                : start && end ?
                    DateTime.fromISO(end).diff(DateTime.fromISO(start)).days <= 2 ?
                        'hours'
                    :   'days'
                :   'days';

            fetch<ImageViewsPerInterval[]>({
                campaignID,
                // metric: metric === Metric.Scans ? Metric.ScanStats : Metric.ViewStats,
                // metric,
                stat: metric === Metric.Scans ? Stat.Scans : Stat.Views, //  'imageScanned' : 'imageViewed',
                timeframe: timeframeAndInterval?.days,
                interval,
                start,
                end,
                // start: start ? (DateTime.fromISO(start).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
                // end: end ? (DateTime.fromISO(end).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
            })
                .then(async results => {
                    // console.log('irdb results', results);

                    // Backfill results for images with days with no views
                    // const image_ids = images.map(image => image.imageID);
                    // const image_ids = results
                    //     .reduce((acc: string[], result: any) => {
                    //         // console.log('result', result);
                    //         const imageIDs = result.value.map((v: any) => v.imageID);
                    //         return acc.concat(imageIDs);
                    //     }, [])
                    //     .unique();
                    // // console.log('image_ids', image_ids);

                    // results = results.map((result: QueryResult<ImageViewsPerInterval[]>, index: number) => {
                    //     for (const image_id of image_ids) {
                    //         if (!(result.value as ImageViewsPerInterval[]).find(r => r.image_id === image_id)) {
                    //             (result.value as ImageViewsPerInterval[]).push({
                    //                 image_id,
                    //                 result: 0,
                    //             });
                    //         }
                    //     }

                    //     return result;
                    // });
                    // console.log('Backfilled results', results);

                    // Intermediate step for pivoting data
                    const resultsByImage = results.reduce(
                        (acc: any, result: QueryResult<ImageViewsPerInterval[]>) => {
                            for (const image of result.value as ImageViewsPerInterval[]) {
                                if (!acc[image.imageID]) {
                                    acc[image.imageID] = [];
                                }
                                acc[image.imageID].push({
                                    // @ts-ignore
                                    timeframe: result.timeframe?.start ?? '',
                                    result: image.count,
                                });
                            }

                            return acc;
                        },
                        {} as Record<string, ImageViewsPerInterval[]>,
                    );
                    // console.log('resultsByImage', resultsByImage);

                    let formattedResults: TopMedia[] = Object.keys(resultsByImage)
                        .map((image_id: string): TopMedia => {
                            return {
                                id: image_id,
                                color: randomColor(image_id),
                                totalViews: resultsByImage[image_id].reduce((acc: number, r: any) => acc + r.result, 0),
                                data: resultsByImage[image_id].map((r: any, index: number) => {
                                    return {
                                        x: r.timeframe,
                                        y: r.result,
                                    };
                                }),
                            };
                        })
                        .filter((media: TopMedia) => media.totalViews > 0)
                        .sort((a: TopMedia, b: TopMedia) => b.totalViews - a.totalViews);
                    // console.log('formattedResults', formattedResults);

                    const imageList = await fetchImageList(formattedResults.map((media: TopMedia) => media.id));
                    formattedResults = formattedResults
                        .filter(media => imageList.some(image => image.imageID === media.id))
                        .map((media: TopMedia) => {
                            const image = imageList.find(image => image.imageID === media.id) as Image;
                            const titleFieldIndex = image.metaArray.findIndex(meta => meta.metaType === MetaType.Title);
                            if (titleFieldIndex !== -1 && !image.metaArray[titleFieldIndex].metaContent.title) {
                                image.metaArray[titleFieldIndex].metaContent = { title: 'Untitled' };
                            } else {
                                image.metaArray.push(newMetaField(MetaType.Title, 'Untitled'));
                            }
                            media.image = image;
                            return media;
                        })
                        .slice(0, 10);

                    console.log('[ANALYTICS] TopMediaChart fetchIrdbAnalytics formattedResults', formattedResults);
                    resolve(formattedResults);
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {
                    setChartsLoaded(true);
                });
        });
    }, [campaignID, metric, timeframeAndInterval, start, end, fetch, fetchImageList]);

    useEffect(() => {
        // console.log('TopMediaChart useEffect', campaignID, images);
        // if (!campaignID || !images || images.length === 0) {
        //     return;
        // }

        if (!campaignID) {
            return;
        }

        setChartsLoaded(false);

        const fetchAnalytics = source === Source.Keen ? fetchKeenAnalytics : fetchIrdbAnalytics;
        fetchAnalytics()
            .then(results => {
                setTopMedia(results);
            })
            .catch((error: any) => {
                console.error('error', error);
                setTopMedia([]);
            });
    }, [client, source, campaignID, images, fetchKeenAnalytics, fetchIrdbAnalytics]);

    return (
        <Stack direction="column" spacing={2}>
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                }}
            >
                Top Scans
            </Typography>

            {false && (
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        // justifyContent: 'center',
                        // alignItems: 'center',
                        // mt: 2,
                        p: 1,
                    }}
                >
                    <Button
                        key="views"
                        // disabled={isDisabledTimeframe(item.timeframe)}
                        sx={{
                            backgroundColor:
                                metric === Metric.Views ? Color.PrimaryLavender : Color.LightLavenderLightMode,
                            color:
                                metric === Metric.Views ? Color.White
                                : darkMode ? Color.White
                                : Color.Black,
                            borderRadius: 1,
                        }}
                        onClick={() => {
                            onMetricChange(Metric.Views);
                        }}
                    >
                        Views
                    </Button>
                    <Button
                        key="scans"
                        // disabled={isDisabledTimeframe(item.timeframe)}
                        sx={{
                            backgroundColor:
                                metric === Metric.Scans ? Color.PrimaryLavender : Color.LightLavenderLightMode,
                            color:
                                metric === Metric.Scans ? Color.White
                                : darkMode ? Color.White
                                : Color.Black,
                            borderRadius: 1,
                        }}
                        onClick={() => {
                            onMetricChange(Metric.Scans);
                        }}
                    >
                        Scans
                    </Button>
                </Stack>
            )}

            {/* {!chartsLoaded || !topMedia.length ?
                <Stack
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 300,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '1.2em',
                            fontWeight: 400,
                            lineHeight: '1.5em',
                            textAlign: 'center',
                        }}
                    >
                        {!chartsLoaded ?
                            'Loading...'
                        :   'There is currently no data available for this time period. Please try another view.'}
                    </Typography>
                </Stack>
            :   <ResponsiveLineChart
                    data={topMedia}
                    interval={timeframeAndInterval?.interval ?? Interval.Daily}
                    tooltip={(point: Point, serie: Serie) => {
                        const imageUrl = serie?.image?.imageUrl;
                        const date = new Date(point.data.x);
                        const dateText = date.toLocaleString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                        });
                        // const dateText =
                        //     isHourly ?
                        //         `${date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} at ${date.toLocaleString('en-US', { hour: 'numeric', hour12: true })}`
                        //     :   date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

                        return (
                            <>
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt="thumbnail"
                                        style={{
                                            width: 44,
                                            height: 40,
                                            objectFit: 'cover',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            marginRight: '1em',
                                            borderLeft: `4px solid ${point.color}`,
                                        }}
                                    />
                                )}
                                <b>
                                    {point.data.yFormatted} view{point.data.yFormatted === '1' ? '' : 's'}
                                </b>
                                &nbsp;on&nbsp;{dateText}
                            </>
                        );
                    }}
                />
            } */}

            <Stack
                direction="row"
                // spacing={1}
                sx={{
                    flexWrap: 'wrap',
                }}
            >
                {topMedia.map((media: TopMedia, index: number) => {
                    return (
                        media.image && (
                            <Stack
                                key={media.id}
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: 'center',
                                    width: '50%',
                                    overflow: 'hidden',
                                    borderBottom: '1px solid rgba(255, 255, 255, .1)',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigateAndScrollToTop(`/dashboard/ircodes/${media.id}`);
                                }}
                            >
                                <Box
                                    component="img"
                                    src={media.image?.imageUrl ?? ''}
                                    sx={{
                                        flexShrink: 0,
                                        flexBasis: 82,
                                        width: 82,
                                        height: 82,
                                        objectFit: 'cover',
                                        visibility: media.image ? 'visible' : 'hidden',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        lineHeight: '16px',
                                        letterSpacing: '0.15em',
                                        textAlign: 'left',
                                    }}
                                >
                                    {index + 1}
                                </Typography>

                                <Box
                                    sx={{
                                        flexShrink: 0,
                                        width: 3,
                                        height: 16,
                                        backgroundColor: media.color,
                                    }}
                                />

                                <Typography
                                    sx={{
                                        flexGrow: 1,

                                        fontFamily: 'Nunito Sans',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textWrap: 'wrap',
                                        textOverflow: 'ellipsis',
                                        // textOverflow: 'wrap',
                                    }}
                                >
                                    {metaContentForMetaType(media.image, MetaType.Title)?.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        flexShrink: 0,

                                        fontFamily: 'Nunito Sans',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        letterSpacing: '0.4px',
                                        textAlign: 'left',
                                    }}
                                >
                                    {media.totalViews}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        ml: '.4em !important',
                                        pr: 2,
                                        opacity: 0.6,
                                    }}
                                >
                                    scans
                                    {/* {metric} */}
                                    {/* {media.totalViews === 1 ? '' : 's'} */}
                                </Typography>
                            </Stack>
                        )
                    );
                })}
            </Stack>
        </Stack>
    );
}
