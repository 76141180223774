import { useCallback, useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Color } from 'src/Color';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { Metric, Source, TimeframeAndInterval } from 'src/types/Analytics';
import useIrdbAnalytics, { Stat, Totals } from 'src/hooks/analytics/useIrdbAnalytics';
import { DateTime } from 'luxon';
import { ServerHit } from './ActivityChart';

interface Props {
    source: Source;
    campaignID: number;
    metric: Metric;
    timeframeAndInterval?: TimeframeAndInterval;

    // Keen
    // timeframe: Timeframe;
    // interval: Interval;

    // IRDB
    // days: number;
    // unit: string; // wanted to call this interval but it conflicts with the Keen interval
    start?: string;
    end?: string;
}

export default function TotalsChart({ source, campaignID, metric, timeframeAndInterval, start, end }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const [totals, setTotals] = useState<Totals>();

    const pctDisplay = useCallback((pct: number | undefined) => {
        return Math.floor((pct ?? 0) * 100);
    }, []);

    const [ctr, setCtr] = useState<number>();

    // const { fetchTotals } = useIrdbAnalytics();
    const fetchKeenAnalytics = useCallback(() => {
        return new Promise<Totals>((resolve, reject) => {
            resolve({
                totalRecent: 0,
                totalUnique: 0,
                totalOlder: 0,
                totalOlderUnique: 0,
                percentTotal: 0,
                percentUnique: 0,
            });
        });
    }, []);

    const { fetch, fetchTotals } = useIrdbAnalytics();
    const fetchIrdbAnalytics = useCallback(() => {
        console.log('[ANALYTICS] TotalsChart fetchAnalytics', campaignID, timeframeAndInterval, start, end, metric);

        return new Promise<Totals>((resolve, reject) => {
            const x = DateTime.fromISO(start!).toISO();

            fetchTotals({
                campaignID,
                // metric,
                stat: metric === Metric.Scans ? Stat.Scans : Stat.Views,
                timeframe: timeframeAndInterval?.days,
                interval: timeframeAndInterval?.unit ?? 'days',
                // start: `${start}-06:00`,
                // end: `${end}-06:00`,

                start,
                end,
                // start: start ? (DateTime.fromISO(start).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
                // end: end ? (DateTime.fromISO(end).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
                // aggregated: true,
                totals: true,
            })
                .then(result => {
                    console.log('results', result);
                    resolve(result);
                })
                .catch((error: any) => {
                    console.error('error', error);
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, timeframeAndInterval, start, end, metric, fetchTotals]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        const fetchAnalytics = source === Source.Keen ? fetchKeenAnalytics : fetchIrdbAnalytics;
        fetchAnalytics()
            .then(totals => {
                setTotals(totals);
            })
            .catch((error: any) => {
                console.error('error', error);
            });

        console.log('diff...', DateTime.fromISO(end!).diff(DateTime.fromISO(start!)));

        fetch<ServerHit>({
            campaignID,
            // metric: metric === Metric.Scans ? Metric.ScanStats : Metric.ViewStats,
            // metric,
            stat: Stat.ServerHits, // metric === Metric.Scans ? Stat.Scans : Stat.Views, //  'imageScanned' : 'imageViewed',
            // timeframe: timeframeAndInterval?.days,
            // interval,
            intervalHits: DateTime.fromISO(end!).diff(DateTime.fromISO(start!)).milliseconds / 1000,
            // intervalHits: 300,
            // intervalHits: 60,
            start,
            end,
            // TODO: The offset start end should be passed to the chart, not readjusted here
            // start: start ? (DateTime.fromISO(start).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
            // end: end ? (DateTime.fromISO(end).toISO() ?? undefined) : undefined, // .toFormat({ zone: 'America/Chicago' }),
        })
            .then(async results => {
                console.log('[ANALYTICS] TotalsChart fetch results', results);

                const totals = results.reduce(
                    (acc, result) => {
                        console.log('result', result);
                        // result.value.imageLinkOpened
                        return {
                            imageScanned:
                                acc.imageScanned +
                                parseInt((result.value as ServerHit).imageScanned as unknown as string),
                            imageLinkOpened:
                                acc.imageLinkOpened +
                                parseInt((result.value as ServerHit).imageLinkOpened as unknown as string),
                        };
                    },
                    {
                        imageScanned: 0,
                        imageLinkOpened: 0,
                    },
                );
                console.log('[ANALYTICS] TotalsChart fetch totals', totals);
                setCtr(totals.imageLinkOpened / totals.imageScanned);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [source, campaignID, start, end, fetchKeenAnalytics, fetchIrdbAnalytics, fetch]);

    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{}}>
            {/* Views */}
            <Stack
                direction="column"
                sx={{
                    width: '100%',

                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color: darkMode ? '#B8AABB' : Color.DarkModeBlack,
                        }}
                    >
                        {/* Views */}
                        Total Scans
                    </Typography>
                    <i className="fa-light fa-eye" style={{ color: darkMode ? '#B8AABB' : Color.DarkModeBlack }} />
                </Stack>
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}
                    >
                        {totals?.totalRecent}
                        {/* 2125 */}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color:
                                pctDisplay(totals?.percentTotal) > 0 ? Color.AnalyticsGreen
                                : pctDisplay(totals?.percentTotal) < 0 ? Color.AnalyticsRed
                                : darkMode ? Color.White
                                : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        {/* {pctDisplay(totals?.percentTotal)}% */}
                    </Typography>
                </Stack>
            </Stack>
            {/* Unique Users */}
            {false && (
                <Stack
                    direction="column"
                    sx={{
                        width: '100%',

                        p: 2,
                        borderRadius: 2,
                        backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                                color: darkMode ? '#B8AABB' : Color.DarkModeBlack,
                            }}
                        >
                            Unique Users
                        </Typography>
                        <i className="fa-light fa-user" style={{ color: darkMode ? '#B8AABB' : Color.DarkModeBlack }} />
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            // flexGrow: 2,
                            // alignContent: 'center',
                            // space-between: 'center',

                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                            }}
                        >
                            {totals?.totalUnique}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                                color:
                                    pctDisplay(totals?.percentUnique) > 0 ? Color.AnalyticsGreen
                                    : pctDisplay(totals?.percentUnique) < 0 ? Color.AnalyticsRed
                                    : darkMode ? Color.White
                                    : Color.PrimaryDarkGrayBlue,
                            }}
                        >
                            {pctDisplay(totals?.percentUnique)}%
                        </Typography>
                    </Stack>
                </Stack>
            )}
            {ctr && (
                <Stack
                    direction="column"
                    sx={{
                        width: '100%',

                        p: 2,
                        borderRadius: 2,
                        backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                                color: darkMode ? '#B8AABB' : Color.DarkModeBlack,
                            }}
                        >
                            CTR
                        </Typography>
                        <i className="fa-light fa-user" style={{ color: darkMode ? '#B8AABB' : Color.DarkModeBlack }} />
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            // flexGrow: 2,
                            // alignContent: 'center',
                            // space-between: 'center',

                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                            }}
                        >
                            {pctDisplay(ctr)}%{/* {totals?.totalUnique} */}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                                color:
                                    pctDisplay(totals?.percentUnique) > 0 ? Color.AnalyticsGreen
                                    : pctDisplay(totals?.percentUnique) < 0 ? Color.AnalyticsRed
                                    : darkMode ? Color.White
                                    : Color.PrimaryDarkGrayBlue,
                            }}
                        >
                            {/* {pctDisplay(ctr)}% */}
                        </Typography>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}
