import { FC, createContext, useContext, useState } from 'react';
import { useCapturedStateWorkaround } from '../hooks/useCapturedStateWorkaround';
import TwoFactorAuthDialog, { TwoFactorAuthDialogProps } from '../components/admin/tools/TwoFactorAuthDialog';
import { AuthProviders, UserType } from './UserContext';
import useApi, { Method } from 'src/hooks/useApi';
import FeedbackContext, { TFeedback } from './FeedbackContext';

interface AnalyticsData {
    Anonymous: { [key: string]: number }[];
    SignedUp: { [key: string]: number }[];
    Images: { [key: string]: number }[];
    Scans: { [key: string]: number }[];
}

// TODO: Why is this plural if it is singular?
export interface UserSearchResults {
    accountLocked: boolean;
    userID: number;
    FBuserID: string;
    accountID: number;
    profileUrl: string;
    lastActive: number;
    userCreated: number;
    internalAdmin: number;
    userType: UserType;
    userTypeDisplay: string;
    email: string;
    phone: string;
    userName: string;
    fullName: string;
    countIRCodes: number;
    authProviders: AuthProviders;
}

// TODO: Use PagedResults<UserSearchResults>, either add the missing properties or drop them since I don't think we use em
interface UserSearchResponse {
    NextOffset: number;
    Count: number;
    Pages: number;
    TimeUsed: number;
    Results: {
        Users: UserSearchResults[];
    };
}

export interface NoMatchItem {
    created: number;
    imageUrl: string;
    longitude: number;
    latitude: number;
    NoMatchUser: UserSearchResults;
}

export interface NoMatchResponse {
    Completed: boolean;
    NextOffset: number;
    Count: number;
    Pages: number;
    TimeUsed: number;
    Environment: string;
    GCFVersion: string;
    Results: {
        List: NoMatchItem[];
    };
}

export interface ImageUser {
    email?: string;
    phone?: string;
    userID?: number;
    fullName?: string;
    userName?: string;
    profileUrl?: string;
}

export interface Ircode {
    internalID: number;
    imageID: string;
    imageCreated: number;
    title: string;
    imageUrl: string;
    userName: string;
    ImageUser: ImageUser;
    longitude: number;
    latitude: number;
}

// TODO: Use PagedResults<Ircode>, either add the missing properties or drop them since I don't think we use em
export interface IrcodeSearchResponse {
    Completed: boolean;
    NextOffset: number;
    Count: number;
    Pages: number;
    TimeUsed: number;
    Environment: string;
    GCFVersion: string;
    Results: {
        Ircodes: Ircode[];
    };
}

export interface SdkItems {
    apikey: string;
    validKey: boolean;
    expires: number;
    Created: boolean;
    Updated: boolean;
    Removed: boolean;
}
export enum AdminType {
    NONE = 'none',
    SUPPORT = 'support',
    ADMIN = 'admin',
}

export interface TAdmin {
    removeUser: (userIDs: number[], twoFACode: string) => Promise<void>;
    fetchAnalyticsData: () => Promise<AnalyticsData | null>;
    fetchUserSearch: (search: string, days: string, excludeIrcode: boolean) => Promise<UserSearchResponse | null>;
    editAdminProfile: (
        email: string,
        fullName: string,
        userName: string,
        phone: string,
        firebaseID: string,
        userID: number,
    ) => Promise<void>;
    modifyAccountType: (userId: number, firebaseId: string, accountType: string) => Promise<boolean>;
    modifyAdminType: (userId: number, firebaseId: string, accountType: AdminType) => Promise<boolean>;
    analyticsRefresh: () => void;
    fetchIrcode: (searchType: string, search: string, showAll: boolean) => Promise<IrcodeSearchResponse | null>;
    fetchNoMatch: (offset: number) => Promise<NoMatchResponse | undefined>;
    excludeIrcode: (exclude: number, imageUserId: number, imageID: string) => Promise<boolean>;
    removeIrcode: (imageID: string, removeNow: boolean) => Promise<boolean>;
    transferIrcode: (
        twoFACode: string,
        imageIDToTransfer: string,
        fromUserID: number,
        fromUserEmail: string,
        fromUserName: string,
        toUserID: number,
        toUserEmail: string,
        toUserName: string,
    ) => Promise<boolean>;
    validate2FA: (twoFACode: string) => Promise<boolean>;
    requestAdminTwoFactorAuth: (title: string, phoneNumber: string, confirm: string, usage: string) => Promise<string>;
    fetchNewSDKKey: () => Promise<SdkItems | null>;
    validateSDKKey: (key: string) => Promise<SdkItems | null>;
    saveSDKKey: (
        apikey: string,
        expires: number,
        bucketName: string,
        associatedUserID?: number,
        projectID?: number,
    ) => Promise<SdkItems | null>;
    updateSDKKey: (
        apikey: string,
        expires: number,
        bucketName: string,
        associatedUserID: number,
        projectID: number,
    ) => Promise<SdkItems | null>;
    removeSDKKey: (apikey: string) => Promise<SdkItems | null>;
    sendPushNotification: (
        userIDs: number[],
        message: string,
        title: string,
        imageID: string,
        twoFACode: string,
    ) => Promise<void>;
}

const AdminContext = createContext<TAdmin | undefined>(undefined);

interface Props {
    children: React.ReactNode;
}

export default AdminContext;

export const AdminProvider: FC<Props> = ({ children }) => {
    const { request } = useApi();
    const [refreshAnalytics, setRefreshAnalytics] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { notify } = useContext(FeedbackContext) as TFeedback;

    const analyticsRefresh = () => {
        setRefreshAnalytics(prev => !prev);
    };

    const fetchAnalyticsData = async (): Promise<AnalyticsData | null> => {
        try {
            const response = await request({
                method: Method.GET,
                path: '/Admin/analytics',
            });
            const data = response.data;
            console.log('data', data);
            return data.Results as AnalyticsData;
        } catch (error) {
            console.error('Error fetching analytics data:', error);
            return null;
        }
    };

    const fetchUserSearch = async (
        search: string,
        days: string,
        excludeAnon: boolean,
    ): Promise<UserSearchResponse | null> => {
        try {
            const response = await request({
                method: Method.GET,
                path: `/Admin/search/user?search=${search}&days=${Number(days)}&limit=100&excludeAnon=${excludeAnon}`,
            });
            console.log('days', days);
            const data = response.data;
            return data as UserSearchResponse;
        } catch (error) {
            console.error('Error fetching user search data:', error);
            return null;
        }
    };

    const removeUser = async (userIDs: number[], twoFACode: string): Promise<void> => {
        console.log('Removing user:', { userIDs, twoFACode });
        try {
            const response = await request({
                method: Method.DELETE,
                path: `/Admin/user`,
                data: {
                    userIDs,
                    twoFACode,
                },
            });
            if (response.data) {
                console.log('User removal successful', response);
            }
        } catch (error: any) {
            if (typeof error.response !== 'undefined') {
                const errorMessage = error.response.data?.error || 'Unknown error during user removal.';
                switch (errorMessage) {
                    case 'InvalidParameters':
                        console.error('Error: Invalid parameters provided.');
                        throw new Error('Invalid parameters provided.');
                    case 'Invalid2FA':
                        console.error('Error: Invalid 2FA code.');
                        throw new Error('Invalid 2FA code.');
                    case '2FA mismatch':
                        console.error('Error: 2FA code does not match.');
                        throw new Error('2FA code mismatch.');
                    case 'TimeOut2FA':
                        console.error('Error: 2FA code has timed out.');
                        throw new Error('2FA code timeout.');
                    default:
                        console.error('User removal failed:', errorMessage);
                        throw new Error(errorMessage);
                }
            }
        }
    };

    const modifyAccountType = async (userId: number, firebaseId: string, accountType: string): Promise<boolean> => {
        try {
            const response = await request({
                method: Method.PUT,
                path: `/Admin/user/modifyAccountType/${accountType}`,
                data: {
                    userID: userId,
                    firebaseID: firebaseId,
                },
            });
            console.log('Response Data:Acct Upgrade', response.data);
            return response.data;
        } catch (error) {
            console.error('Error modifying account type:', error);
            return false;
        }
    };

    const modifyAdminType = async (userId: number, firebaseId: string, adminType: AdminType): Promise<boolean> => {
        try {
            const response = await request({
                method: Method.PUT,
                path: `/Admin/user/modifyAdminType/${adminType}`,
                data: {
                    userID: userId,
                    firebaseID: firebaseId,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error modifying account type:', error);
            return false;
        }
    };

    const editAdminProfile = async (
        email: string,
        fullName: string,
        userName: string,
        phone: string,
        firebaseID: string,
        userID: number,
    ): Promise<void> => {
        try {
            const response = await request({
                method: Method.PUT,
                path: '/Admin/user',
                data: {
                    email,
                    userName,
                    fullName,
                    phone,
                    firebaseID,
                    userID,
                },
            });
            alert('User was updated successfully');
            console.log('Response Data:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error updating profile: backend', error);
        }
    };

    const fetchIrcode = async (
        searchType: string,
        search: string,
        showAll: boolean,
    ): Promise<IrcodeSearchResponse | null> => {
        try {
            const response = await request({
                method: Method.GET,
                path: `/Admin/search/ircode?type=${searchType}&search=${search}&limit=100&showAll=${searchType === 'scanned' ? showAll : 'false'}`,
            });
            const data = response.data;
            console.log('data', data);
            return data as IrcodeSearchResponse;
        } catch (error) {
            console.error('Error fetching ircode search data:', error);
            return null;
        }
    };

    const fetchNoMatch = async (offset: number = 0): Promise<NoMatchResponse | undefined> => {
        try {
            const response = await request({
                method: Method.GET,
                path: `/Admin/noMatches?limit=100&offset=${offset}`,
            });
            const data = response.data as NoMatchResponse;

            data.Results.List = data.Results.List.map(item => ({
                ...item,
                NoMatchUser:
                    typeof item.NoMatchUser === 'string' ?
                        (JSON.parse(item.NoMatchUser) as UserSearchResults)
                    :   item.NoMatchUser,
            }));
            return data;
        } catch (error) {
            console.error('Error fetching no match data:', error);
            return undefined;
        }
    };

    const validate2FA = async (twoFACode: string): Promise<boolean> => {
        console.log(`Validating 2FA code: ${twoFACode}`);
        try {
            const response = await request({
                method: Method.GET,
                path: `/Admin/validate2FA/${twoFACode}`,
            });
            if (response.data.TwoFAValid) {
                return true;
            } else if (response.data.error) {
                switch (response.data.error) {
                    case 'Invalid2FA':
                        throw new Error('The 2FA code provided is invalid.');
                    case '2FA mismatch':
                        throw new Error('The provided 2FA code does not match the expected value.');
                    case 'TimeOut2FA':
                        throw new Error('The 2FA code has timed out.');
                    default:
                        throw new Error('2FA validation failed for an unknown reason.');
                }
            } else {
                throw new Error('2FA validation failed due to an unexpected error.');
            }
        } catch (error) {
            console.error('Error validating 2FA:', error);

            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unexpected error occurred during 2FA validation.');
            }
            return false;
        }
    };

    const transferIrcode = async (
        twoFACode: string,
        imageIDToTransfer: string,
        fromUserID: number,
        fromUserEmail: string,
        fromUserName: string,
        toUserID: number,
        toUserEmail: string,
        toUserName: string,
    ): Promise<boolean> => {
        try {
            const response = await request({
                method: Method.POST,
                path: '/Admin/ircode/transfer',
                data: {
                    twoFACode,
                    imageIDToTransfer,
                    fromUserID,
                    fromUserEmail,
                    fromUserName,
                    toUserID,
                    toUserEmail,
                    toUserName,
                },
            });
            console.log('Response Data:', response.data);
            if (response.data.Completed === true) {
                alert('Transfer was successful');
                return true;
            }
            console.error(response.data.Results.error);
            return false;
        } catch (error: any) {
            const errorMessage = error.message;
            console.error('Transfer error:', errorMessage, error);
            return false;
        }
    };

    const removeIrcode = async (imageID: string, removeNow: boolean): Promise<boolean> => {
        console.log('Removing IRC code:', { imageID, removeNow });
        try {
            const response = await request({
                method: Method.DELETE,
                path: `/Admin/ircode/?imageID=${imageID}&removeNow=${removeNow}`,
            });
            return response.data.ImageRemoved;
        } catch (error) {
            console.error('Error removing IRC code:', error);
            return false;
        }
    };

    const excludeIrcode = async (exclude: number, imageUserID: number, imageID: string): Promise<boolean> => {
        console.log('Data being sent:', { exclude, imageUserID, imageID });
        try {
            const response = await request({
                method: Method.PUT,
                path: '/Admin/ircode/exclude',
                data: {
                    exclude,
                    imageUserID,
                    imageID,
                },
            });
            if (response.data.Results.Updated === true) {
                return true;
            }
            return false;
        } catch (error: any) {
            const errorMessage = error.message;
            console.error('Transfer error:', errorMessage, error);
            return false;
        }
    };

    const [twoFactorAuthDialogProps, setTwoFactorAuthDialogProps, twoFactorAuthDialogPropsRef] =
        useCapturedStateWorkaround<TwoFactorAuthDialogProps>({
            open: false,
            title: '',
            phoneNumber: '',
            confirm: '',
            onComplete: () => {},
            onClose: () => {},
        });

    const requestTwoFactorAuthCode = async () => {
        try {
            const response = await request({
                method: Method.PUT,
                path: '/Admin/request2FA',
                data: {
                    usage: 'AdminFunction',
                },
            });
        } catch (error) {
            console.error('Error requesting 2FA for Admin:', error);
            let errorMessage = '';
            if (typeof error === 'object' && error !== null && 'response' in error) {
                const err = error as { response?: { data?: { error?: string } } };
                switch (err.response?.data?.error) {
                    case 'Twilio':
                        errorMessage = 'There was an error with the Twilio service.';
                        break;
                    case 'InvalidPhone':
                        errorMessage = 'The phone number provided is invalid.';
                        break;
                    case 'FailedToUpdate2FA':
                        errorMessage = 'The user record could not be updated with 2FA details.';
                        break;
                }
            }
            setError(errorMessage);
        }
    };

    const sendPushNotification = async (
        userIDs: number[],
        message: string,
        title: string,
        imageID: string,
        twoFACode: string,
    ): Promise<void> => {
        try {
            const totalUsers = userIDs.length;

            const response = await request({
                method: Method.POST,
                path: '/Push/marketing',
                data: {
                    userIDs,
                    message,
                    title,
                    imageID,
                    twoFACode,
                },
            });

            const pushesSent = response.data.Results?.PushesSent;

            await notify(
                'Push Notification Sent',
                `Successfully sent pushes to ${pushesSent} out of ${totalUsers} users.`,
            );
        } catch (error) {
            notify('Error sending push notification:', error as string);
            throw error;
        }
    };

    const showTwoFactorAuthForm = (title: string, phoneNumber: string, confirm: string) =>
        new Promise<string>((resolve, reject) => {
            setTwoFactorAuthDialogProps({
                open: true,
                title,
                phoneNumber,
                confirm,
                onComplete: code => {
                    resolve(code);
                    setTwoFactorAuthDialogProps({
                        ...twoFactorAuthDialogPropsRef.current,
                        open: false,
                    });
                },
                onClose: () => {
                    reject('The Two Factor Auth was cancelled.');
                    setTwoFactorAuthDialogProps({
                        ...twoFactorAuthDialogPropsRef.current,
                        open: false,
                    });
                },
            });
        });

    const fetchNewSDKKey = async (): Promise<SdkItems | null> => {
        try {
            const response = await request({
                method: Method.GET,
                path: '/IRKitSDK/newKey',
            });
            const data = response.data;
            return data.Results as SdkItems;
        } catch (error) {
            console.error('Error fetching new SDK key:', error);
            return null;
        }
    };

    const validateSDKKey = async (key: string): Promise<SdkItems | null> => {
        try {
            const response = await request({
                method: Method.GET,
                path: `/IRKitSDK/validate/${key}`,
            });
            const data = response.data;
            console.log('validation', data);
            return data.Results as SdkItems;
        } catch (error) {
            console.error('Error validating SDK key:', error);
            return null;
        }
    };

    const saveSDKKey = async (
        apikey: string,
        expires: number,
        bucketName: string,
        associatedUserID: number = 1,
        projectID: number = 1,
    ): Promise<SdkItems | null> => {
        console.log('Data being sent:', { apikey, expires, bucketName, associatedUserID, projectID });
        try {
            const response = await request({
                method: Method.POST,
                path: '/IRKitSDK',
                data: {
                    apikey,
                    expires,
                    bucketName,
                    associatedUserID,
                    projectID,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error saving SDK key:', error);
            return null;
        }
    };

    const updateSDKKey = async (
        apikey: string,
        expires: number,
        bucketName: string,
        associatedUserID: number,
        projectID: number,
    ): Promise<SdkItems | null> => {
        try {
            const response = await request({
                method: Method.PUT,
                path: `/IRKitSDK/${apikey}`,
                data: {
                    expires,
                    bucketName,
                    associatedUserID,
                    projectID,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating SDK key:', error);
            return null;
        }
    };

    const removeSDKKey = async (apikey: string): Promise<SdkItems | null> => {
        try {
            const response = await request({
                method: Method.DELETE,
                path: `/IRKitSDK/${apikey}`,
            });
            return response.data;
        } catch (error) {
            console.error('Error removing SDK key:', error);
            return null;
        }
    };

    return (
        <AdminContext.Provider
            value={{
                removeUser,
                fetchAnalyticsData,
                fetchUserSearch,
                editAdminProfile,
                modifyAccountType,
                modifyAdminType,
                analyticsRefresh,
                fetchIrcode,
                fetchNoMatch,
                transferIrcode,
                validate2FA,
                removeIrcode,
                excludeIrcode,
                fetchNewSDKKey,
                validateSDKKey,
                saveSDKKey,
                updateSDKKey,
                removeSDKKey,
                requestAdminTwoFactorAuth: async (title: string, phoneNumber: string, confirm: string) => {
                    await requestTwoFactorAuthCode();
                    return showTwoFactorAuthForm(title, phoneNumber, confirm);
                },
                sendPushNotification,
            }}
        >
            {children}
            <TwoFactorAuthDialog {...twoFactorAuthDialogProps} />
        </AdminContext.Provider>
    );
};

export const useAdmin = () => useContext(AdminContext);
