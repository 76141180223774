import { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    Button,
    Container,
    Dialog,
    Drawer,
    Fade,
    IconButton,
    Stack,
    SwipeableDrawer,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material';
import { useCapturedStateWorkaround } from '../hooks/useCapturedStateWorkaround';
import MediaContext, {
    TMedia,
    ImageOperation,
    Debug as IDebug,
    Query,
    imageOperationFromImage,
    imageFromImageOperation,
    Init,
    RegistrationStatus,
    similarImagesFromImageOperation,
} from '../contexts/MediaContext';
import { Image } from '../types/Image';
import { Type } from '../contexts/Operation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Info from './image/Info';
import { ZIndex } from '../App';
import LiveView, { LiveViewRef } from './LiveView';
import { Color } from '../Color';
import Available from './image/Available';
import useExpiringState from '../hooks/useExpiringState';
import StackNav from './StackNav';
import IrdbDrawer from './general/Drawer';
import Cropper from './image/CropperExtended';
import UserContext, { TUser, SignInResult, SignInStatus } from '../contexts/UserContext';
import Profile from './Profile/Profile';
import SignIn from './auth/SignIn';
import { Puller } from './general/Puller';
import EventContext, { TEvent, Event as IRCODEEvent } from '../contexts/EventContext';
import ThemeContext, { TTheme } from '../contexts/ThemeContext';
import EnvContext, { TEnv } from '../contexts/EnvContext';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import WordMarkWhite from '../images/WordMarkWhite.svg';
import useTorch from '../hooks/useTorch';
import useCameraPermissions from 'src/hooks/useCameraPermissions';
import { metaContentForMetaType } from 'src/contexts/MetaContext';
import { MetaField } from '../types/MetaField';
import isMobileJs from 'ismobilejs';
import { useOrientation } from '@uidotdev/usehooks';
import { adminIrcodeAccept, ircodeAccept } from 'src/util/reactDropzone';
import usePageTitle from 'src/hooks/usePageTitle';
import useOcr from 'src/hooks/useOcr';
import { MetaType } from '../types/MetaTypes';
import { TLink } from '../types/Link';
import { WithBulkUploaderProvider } from 'src/contexts/specialized/BulkUploaderContext';
import FirebaseContext, { TFirebase } from 'src/contexts/FirebaseContext';
import { ControlledPromise } from 'src/util/ControlledPromise';
import useFetchImage from 'src/hooks/be/useFetchImage';
import { Debug } from './webapp/Debug';
import useKeenAnalytics from 'src/hooks/analytics/useKeenAnalytics';
import useIrdbAnalytics from 'src/hooks/analytics/useIrdbAnalytics';

const imageToScanToSiteLink = (image: Image) =>
    (metaContentForMetaType(image, MetaType.Link) as TLink)?.links.find(link => link.onScanDisplay);

const WebApp = WithBulkUploaderProvider(function WebApp() {
    const { client, ocrEnabled } = useContext(EnvContext) as TEnv;
    const { startTrace, stopTrace } = useContext(FirebaseContext) as TFirebase;
    const {
        imageLinkOpened: imageLinkOpenedKeen,
        imageScanned: imageScannedKeen,
        imageViewed: imageViewedKeen,
    } = useKeenAnalytics();
    const {
        imageLinkOpened: imageLinkOpenedIrdb,
        imageScanned: imageScannedIrdb,
        imageViewed: imageViewedIrdb,
    } = useIrdbAnalytics();
    const navigate = useNavigate();
    const theme = useTheme();
    const { findBadge } = useOcr();
    const { publish } = useContext(EventContext) as TEvent;
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { user, userIsAnonymous } = useContext(UserContext) as TUser;
    const { init, prep, foveate, upload, query, quick, add, load } = useContext(MediaContext) as TMedia;
    const { fetchImage } = useFetchImage();
    const { confirm } = useContext(FeedbackContext) as TFeedback;
    const isMobile = isMobileJs().phone;
    const isLandscapeMode = useOrientation().type.includes('landscape');

    // DEBUG...
    const [searchParams] = useSearchParams();
    const debugEnabled = searchParams.get('debug');
    // ...DEBUG

    const fade = 500;

    const liveViewRef = useRef<LiveViewRef>();

    const [isProcessing, setIsProcessing] = useState(false);
    const [imageOperation, setImageOperation] = useState<ImageOperation<any>>();
    const [unfoundImage, setUnfoundImage] = useExpiringState<string>(5000, undefined, value => {
        if (value) {
            URL.revokeObjectURL(value);
        }
    });
    const [imageCreating, setImageCreating] = useState<Image>();
    const [file, setFile, fileRef] = useCapturedStateWorkaround<File>();
    const [errorMessage, setErrorMessage] = useExpiringState<string>(10000, undefined);
    const [closeMatchImage, setCloseMatchImage] = useExpiringState<Image>(10000, undefined);

    const [showFaq, setShowFaq] = useState(false);
    const [iframeURL, setIframeURL] = useState('');
    const [showClientLanding, setShowClientLanding] = useState(false);
    const [isReloading, setIsReloading] = useState(false);

    const imageCancelRef = useRef<() => void>();
    const ocrCancelRef = useRef<() => void>();

    const [debug, setDebug] = useState<IDebug>();

    // TODO: Find a better name (?)
    const setImageOperationWithScanToSite = useCallback(
        (operation: ImageOperation<Query> | undefined) => {
            if (!operation) return;
            const image = imageFromImageOperation(operation);
            if (!image) {
                setImageOperation(operation);
                return;
            }

            imageScannedKeen(image);
            imageScannedIrdb(image);

            const scanToSiteLink = imageToScanToSiteLink(image);
            const privateDetails = scanToSiteLink?.privateDetails;
            if (scanToSiteLink) {
                // url constructor might throw an error if the url is invalid, which we don't want the outer catch to catch and show to the user as an error message.
                try {
                    const url = new URL(scanToSiteLink.url);
                    const newWindow = window.open(url.href, '_blank');
                    imageLinkOpenedKeen(imageFromImageOperation(operation)!, scanToSiteLink);
                    imageLinkOpenedIrdb(imageFromImageOperation(operation)!, scanToSiteLink);
                    if (privateDetails) {
                        // The info component doesn't open in this case, so we need to call image analytics here
                        imageViewedKeen(imageFromImageOperation(operation)!);
                        imageViewedIrdb(imageFromImageOperation(operation)!);
                    }

                    if (newWindow == null) {
                        // If we can't open a new tab, keep the imageOperation in sessionStorage and go to the destination
                        sessionStorage.setItem('scanSiteOperation', JSON.stringify(operation));
                        location.href = url.href;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            if (!privateDetails) {
                // IRCode info isn't private
                setImageOperation(operation);
            }
        },
        [setImageOperation],
    );

    // useEffect(() => {
    //     // return () => (imagesRef.current ?? []).forEach(image => URL.revokeObjectURL(image.preview));
    //     return () => {
    //         if (imageOperation?.preview) {
    //             URL.revokeObjectURL(imageOperation?.preview);
    //         }
    //     };
    // }, []);

    useEffect(() => {
        if (!isMobile) {
            navigate('/dashboard');
        }
    }, [isMobile, navigate]);

    const ocrSearchPromise = async (file: File, ocrEnabled: boolean): Promise<ImageOperation<Query> | undefined> => {
        if (!ocrEnabled) {
            return;
        }

        try {
            const { promise, cancel } = findBadge({ file });
            ocrCancelRef.current = cancel;
            const imageId = await promise;
            console.log('[OCR] imageId', imageId);

            if (!imageId) {
                return;
            }

            // TODO: Can't this fail (image is non-undefined, but doesn't exist)?
            const image = await load(imageId);

            // TODO: These next two lines are sloppy, we need a way to just have a "loaded" imageOperation generic
            const imageOperation = await imageOperationFromImage(image, Type.Query);
            imageOperation.operation.Results.ImageAlreadyExists = true;

            return imageOperation;
        } catch (error) {
            console.warn(error);
            return undefined;
        }
    };

    const imageSearchPromise = async (file: File): Promise<ImageOperation<Query> | undefined> => {
        const id = crypto.randomUUID();
        const imageOperation: ImageOperation<Init> = {
            id,
            type: 'image',
            file,
            meta: [],
            operation: {
                type: Type.Init,
                status: '',
                Pending: true,
                Completed: false,
            },
            status: RegistrationStatus.Pending,
        };

        const { promise: prepPromise, cancel: prepCancel } = prep(imageOperation);
        imageCancelRef.current = prepCancel;
        const p = await prepPromise;
        setImageOperation(p);

        const { promise: foveatePromise, cancel: foveateCancel } = foveate(p);
        imageCancelRef.current = foveateCancel;
        const f = await foveatePromise;
        setImageOperation(f);

        const { promise: queryPromise, cancel: queryCancel } = query(f, (progress: ImageOperation<Query>) => {
            setImageOperation(progress);
        });
        imageCancelRef.current = queryCancel;
        const q = await queryPromise;
        // We don't want to set the image operation here because we may not go with this result.
        // setImageOperation(q);

        return q;
    };

    const searchPromises = async (file: File, ocrEnabled: boolean): Promise<ImageOperation<Query> | undefined> => {
        setCloseMatchImage(undefined);
        setErrorMessage(undefined);
        return new Promise((resolve, reject) => {
            const imageResultPromise = new ControlledPromise<ImageOperation<Query> | undefined>();
            imageResultPromise.promise.catch(() => {
                console.log('Image Search Promise Rejected');
            });

            console.log('Starting Image Search');
            imageSearchPromise(file)
                .then(imageResult => {
                    console.log('Image Search Resolved');
                    imageResultPromise.resolve(imageResult);

                    if (imageResult?.operation.Results?.ImageAlreadyExists) {
                        console.log('Use Image Search Result');
                        resolve(imageResult);
                    } else {
                        // Wait for OCR
                        console.log('No Image Search Result, wait on OCR if needed');
                    }
                })
                .catch(error => {
                    console.error(error);
                    imageResultPromise.reject(error);
                });

            console.log('Starting OCR Search');
            ocrSearchPromise(file, ocrEnabled)
                .then(async ocrResult => {
                    console.log('OCR Search Resolved');
                    console.log('Wait on Image Search if needed');
                    let imageResult;
                    try {
                        imageResult = await imageResultPromise.promise;
                    } catch (error) {
                        console.log('Image Search Promise Rejected, will try to use OCR');
                    }

                    if (imageResult?.operation.Results?.ImageAlreadyExists) {
                        // We used the imageResult
                        console.log('Going with already resolved imageResult');
                    } else if (ocrResult) {
                        console.log('Use OCR Search Result');
                        // Use scanned image when OCR result doesn't have image:
                        // if (!ocrResult?.operation.Results?.Image?.imageUrl) {
                        //     ocrResult.operation.Results!.Image!.imageUrl = URL.createObjectURL(file); //.operation.Results?.Image?.imageUrl
                        // }
                        resolve(ocrResult);
                    } else if (client) {
                        console.log('No results and no add functionality');
                        // Client sites don't allow IRCODE creation
                        // Remove CloseMatchImage for now
                        // setCloseMatchImage(imageResult?.operation?.Results?.Images?.[0]);
                        setErrorMessage('No exact results found.');
                        setUnfoundImage(URL.createObjectURL(file));
                        resolve(undefined);
                    } else if (imageResult?.operation.Results?.ImageAlreadyExists === false) {
                        console.log('No results will try to add');
                        resolve(imageResult);
                    } else {
                        reject('Unknown Error');
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        });
    };

    useEffect(() => {
        if (file === undefined) {
            return;
        }

        setIsProcessing(true);

        const performanceCounter = startTrace('image_match_process');

        searchPromises(file, ocrEnabled)
            .then(setImageOperationWithScanToSite)
            .catch(error => {
                console.error(error);
                setErrorMessage(error.message);
            })
            .finally(() => {
                stopTrace(performanceCounter);
                setIsProcessing(false);
            });
    }, [file]);

    const onDrop = useCallback(async (files: File[]) => {
        const file = files[0];
        // TODO: Maybe block further uploads until this is done?
        // TODO: Maybe only check for new files here...

        setFile(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: !user?.internalAdmin ? ircodeAccept : adminIrcodeAccept,
    });

    useEffect(() => {
        const handlePageShow = () => {
            const scanSiteOperation = sessionStorage.getItem('scanSiteOperation');
            // Restore the last IRCODE operation if the user navigates back to the page from a Scan-to-Site
            if (scanSiteOperation) {
                const parsedOperation = JSON.parse(scanSiteOperation) as ImageOperation<Query>;
                sessionStorage.removeItem('scanSiteOperation');
                setIsReloading(true);
                setImageOperation(undefined);

                const scanToSiteLink =
                    parsedOperation.operation.Results?.Image ?
                        imageToScanToSiteLink(parsedOperation.operation.Results.Image)
                    :   undefined;
                const privateDetails = scanToSiteLink?.privateDetails;
                // Don't restore the last IRCODE if it had Private Details enabled
                setTimeout(() => {
                    if (!privateDetails) {
                        setImageOperation(parsedOperation);
                    }
                    setIsReloading(false);
                }, 500);
            }
        };

        window.addEventListener('pageshow', handlePageShow);
        return () => {
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, []);

    const [authOpen, setAuthOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState<string>();

    const { isTorchOn, isTorchSupported, toggle: toggleTorch, turnOff: turnOffTorch } = useTorch();
    const [showCropper, setShowCropper] = useState(false);
    const { hasPermission } = useCameraPermissions();

    const [showWalmart, setShowWalmart] = useState(client === 'walmart');

    const onAdd = async (operation: ImageOperation<Query>) => {
        prep(operation).promise.then(r => console.log('Res: ', r));
        setImageOperation(operation);
        if (operation.operation.Results?.Image) {
            setImageCreating(operation.operation.Results.Image);
            return;
        }
        const image = {
            imageUrl: operation.cropped?.preview ?? operation.original?.preview ?? '',
            metaArray: [] as MetaField[],
        } as Image;
        setImageCreating(image);
    };

    const showAvailable =
        !imageCreating &&
        imageOperation !== undefined &&
        imageOperation.operation.type === Type.Query &&
        imageOperation.operation.Results?.ImageAlreadyExists === false &&
        !client;
    const showInfo =
        !!imageCreating ||
        (imageOperation !== undefined &&
            ((imageOperation.operation.type === Type.Query &&
                imageOperation.operation.Results?.ImageAlreadyExists === true) ||
                imageOperation.operation.type === Type.Add));
    const isCameraDisabled =
        authOpen ||
        profileOpen ||
        showFaq ||
        showAvailable ||
        showCropper ||
        showInfo ||
        isProcessing ||
        !!iframeURL ||
        showWalmart ||
        isReloading;

    const onCropperProgress = (progress: ImageOperation<any>) => {
        setImageOperation(progress);
    };

    useEffect(() => {
        if (isTorchSupported && isCameraDisabled) {
            turnOffTorch();
        }
    }, [isTorchSupported, isCameraDisabled, turnOffTorch]);

    useEffect(() => {
        if (!showInfo) {
            setShowWalmart(client === 'walmart');
        }
    }, [showInfo]);
    useEffect(() => {
        if (!showAvailable) {
            setShowWalmart(client === 'walmart');
        }
    }, [showAvailable]);

    usePageTitle('Home');

    if (isMobile) {
        if (isLandscapeMode) {
            return (
                <Stack
                    direction="column"
                    spacing={8}
                    sx={{
                        display: 'flex',
                        height: '100vh',
                        p: 2,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: Color.PrimaryDarkGrayBlue,
                    }}
                    style={{
                        height: '100dvh',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            objectFit: 'contain',
                            width: 'auto',
                            height: '56px',
                        }}
                        src={WordMarkWhite}
                        alt=""
                    />
                    <Typography
                        variant="mainFont6"
                        sx={{
                            textAlign: 'center',
                            color: Color.White,
                        }}
                    >
                        Please rotate your device to portrait orientation to use IRCODE.
                    </Typography>
                </Stack>
            );
        }

        return (
            <Box
                id="WebApp"
                sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                style={{
                    height: '100dvh', //device viewport height (with fallback in sx)
                }}
            >
                {/* Profile Header */}
                {!client && !isProcessing && (
                    <Stack
                        sx={{
                            position: 'absolute',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            zIndex: ZIndex.Menu,
                            color: Color.White,
                            background: 'rgba(0, 0, 0, 0.5)',
                            p: 2,
                            top: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: 'center',
                                minHeight: '2em',
                            }}
                            onClick={() => {
                                if (!user || userIsAnonymous) {
                                    setAuthOpen(true);
                                } else {
                                    setProfileOpen(true);
                                }
                            }}
                        >
                            {user && !userIsAnonymous && (
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontWeight: 600,
                                    }}
                                    mr={1}
                                >
                                    {user?.userName || `IRCODE USER ${user?.userID ?? ''}`}
                                </Typography>
                            )}
                            {user?.profileUrl ?
                                <img
                                    src={user.profileUrl}
                                    alt={user?.userName || `IRCODE USER ${user?.userID ?? ''}`}
                                    style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' }}
                                />
                            :   <i className="fa-regular fa-circle-user fa-2xl" />}
                        </Stack>
                    </Stack>
                )}

                <LiveView
                    liveViewRef={liveViewRef}
                    pause={isCameraDisabled}
                    // pauseStream={!!currentLiveVisualSearchResult}
                    onPhoto={setFile}
                    // onStream={(base64, size, timestamp) => {
                    //     addtoQueue(base64, size, timestamp);
                    // }}
                    theme={theme}
                    onDevices={function (devices: MediaDeviceInfo[]): void {
                        // TODO: Get rid of this
                    }}
                />

                {false && (
                    <Box
                        onClick={() => {
                            setShowWalmart(false);
                        }}
                        sx={{
                            background: '#fff url(/images/clients/walmart_screen.png) 50% 0 / 100% auto no-repeat',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 9999,
                        }}
                    />
                )}

                {/* Capture */}
                <Fade in={!isProcessing} timeout={fade}>
                    <Container
                        sx={{
                            position: 'absolute',
                            background: 'rgba(0, 0, 0, 0.5)',
                            height: 130,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 3,
                            px: 2,
                            py: 4,
                        }}
                    >
                        {/* TODO: Why am I still using Toolbar? */}
                        <Toolbar
                            sx={{
                                flexGrow: 1,
                                justifyContent: 'space-around',
                            }}
                        >
                            {(!client && (
                                <span {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <IconButton
                                        style={{
                                            color: Color.White,
                                            height: '2.65rem',
                                            width: '2.65rem',
                                            fontSize: '1.8rem',
                                        }}
                                    >
                                        <i className="fa-regular fa-images"></i>
                                    </IconButton>
                                </span>
                            )) || <div style={{ width: '2.65rem' }} />}
                            <IconButton
                                disabled={!hasPermission}
                                sx={{
                                    height: 64,
                                    width: 64,
                                    opacity: hasPermission ? 1 : 0.5,
                                    transition: 'opacity 0.3s',
                                    '.MuiTouchRipple-ripple, .MuiTouchRipple-child': {
                                        borderRadius: 'inherit',
                                        backgroundColor: Color.PrimaryLavender,
                                    },
                                }}
                                onClick={() => {
                                    // TODO: Get typing to work
                                    // @ts-ignore
                                    liveViewRef.current?.capture();
                                }}
                            >
                                <img
                                    src={!client ? require('../images/LogoMark.png') : '/images/clients/shutter.svg'}
                                    alt="Logo"
                                    style={{
                                        userSelect: 'none',
                                        pointerEvents: 'none',
                                        width: 64,
                                        height: 64,
                                        objectFit: 'contain',
                                    }}
                                />
                            </IconButton>
                            {(isTorchSupported && (
                                <IconButton
                                    onClick={toggleTorch}
                                    style={{
                                        color: Color.White,
                                        height: '2.65rem',
                                        width: '2.65rem',
                                        fontSize: '1.4rem',
                                    }}
                                >
                                    <i
                                        className={`${isTorchOn ? 'fa-solid' : 'fa-regular'} fa-flashlight fa-rotate-270`}
                                    />
                                </IconButton>
                            )) || <div style={{ width: '2.65rem' }} />}
                        </Toolbar>
                    </Container>
                </Fade>

                {/* Searching Animation */}
                <Fade in={isProcessing} timeout={fade}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 2,
                        }}
                    >
                        {/* Image Preview */}
                        <Box
                            sx={{
                                background: Color.PrimaryDarkGrayBlue,
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                        >
                            <img
                                src={
                                    imageOperation?.cropped?.preview ??
                                    imageOperation?.original?.preview ??
                                    unfoundImage
                                }
                                style={{
                                    borderRadius: '1em',
                                    position: 'absolute',
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: 'transform 1s .2s',
                                    ...(isProcessing && {
                                        transform: 'scale(0.8)',
                                    }),
                                }}
                                alt=""
                            />
                        </Box>

                        {/* Grid */}
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 3,
                                background:
                                    'linear-gradient(rgba(255, 255, 255, 0.13) 1px, transparent 1px) 0 0, linear-gradient(90deg, rgba(255, 255, 255, 0.13) 1px, transparent 1px) 0 0',
                                backgroundSize: '1.5em 1.5em',
                            }}
                        >
                            {/* Wipe Effect */}
                            <style>{`
                            @keyframes mobile-scan-wipe {
                                from {
                                    transform: translateY(calc(-100% - 10vh));
                                }

                                to {
                                    transform: translateY(110vh);
                                }
                            }
                            `}</style>
                            {isProcessing && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '6em',
                                        background:
                                            'linear-gradient(transparent, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2), transparent)',
                                        filter: 'blur(.3em)',
                                        animation: 'mobile-scan-wipe 2.5s .5s infinite both alternate linear',
                                    }}
                                />
                            )}

                            {/* Cancel Button */}
                            <Stack
                                direction="column"
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    bottom: '1em',
                                    left: 0,
                                    zIndex: 2,
                                    justifyContent: 'center',
                                    opacity: 0,
                                    transition: 'opacity 0.5s 1.5s',
                                    ...(isProcessing && {
                                        opacity: 1,
                                    }),
                                }}
                            >
                                <Button
                                    variant="irdbText"
                                    sx={{
                                        alignSelf: 'center',
                                        color: Color.White,
                                        letterSpacing: '0.1em',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        imageCancelRef.current?.();
                                        ocrCancelRef.current?.();
                                    }}
                                >
                                    <i className="fa-solid fa-circle-xmark" />
                                    &nbsp;Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>

                {/* Auth */}
                <Dialog
                    fullScreen
                    // transitionDuration={transitionDuration}
                    open={authOpen}
                    onClose={(event: SyntheticEvent<{}, Event>) => {
                        console.log('onClose');
                        setAuthOpen(false);
                    }}
                    style={{
                        // This only works on `style`, not `sx`
                        zIndex: ZIndex.AuthSheet,
                    }}
                    sx={{
                        '& .MuiDialog-paper': {
                            backgroundColor: Color.Black,
                            backgroundImage: {
                                xs: 'none',
                                sm: 'url(/images/signInBackground.svg)',
                            },
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'top right',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }}
                >
                    <SignIn
                        onClose={() => setAuthOpen(false)}
                        onComplete={(result: SignInResult) => {
                            // onClose(result);
                            switch (result.status) {
                                case SignInStatus.Success:
                                    setAuthOpen(false);
                                    // closeMenu();
                                    break;
                                case SignInStatus.SignUp:
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                </Dialog>

                {/* Profile */}
                <SwipeableDrawer
                    anchor="bottom"
                    open={!!user && profileOpen}
                    onOpen={() => setProfileOpen(true)}
                    onClose={() => setProfileOpen(false)}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    // Not called 🙃
                    // onScroll={ () => {
                    // } }
                    onScrollCapture={element => {
                        // console.log('element', element);
                        // TODO: Can we really not just limit scrolling callbacks?
                        if ((element.target as HTMLElement).id === 'navTiles') {
                            return;
                        }

                        const { scrollTop, scrollHeight, clientHeight } = element.target as HTMLElement;
                        if (scrollTop > 0 && scrollHeight - scrollTop === clientHeight) {
                            console.log('Scrolled to bottom');
                            publish(IRCODEEvent.ProfileScrolledToBottom);
                        }
                    }}
                    style={{
                        zIndex: ZIndex.ProfileSheet,
                    }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: '100%',
                            height: '100%',
                            overflow: 'scroll',
                            backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                            backgroundImage: 'none',
                        },
                    }}
                >
                    <Puller />
                    <Profile
                        selectedImageId={selectedImageId}
                        setSelectedImageId={setSelectedImageId}
                        toggle={() => setProfileOpen(false)}
                        setAuthOpen={setAuthOpen}
                    />
                </SwipeableDrawer>

                {/* Available */}
                <SwipeableDrawer
                    anchor="bottom"
                    open={showAvailable}
                    disableSwipeToOpen={true}
                    ModalProps={{
                        // This allows the changing of the image to be observed
                        keepMounted: false,
                    }}
                    onClose={function (event: SyntheticEvent<{}, Event>): void {
                        setImageOperation(undefined);
                    }}
                    onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                    sx={{
                        '& .MuiDrawer-paper': {
                            transition: `height ${fade}ms`,
                            // height: availableDrawerHeight,
                            height: '100%',
                            // The default has a background image that is actually a gradient and prevented us from having rounded corners
                            backgroundImage: 'none',
                            backgroundColor: 'transparent',
                        },
                        '& .MuiDrawer-paper > .MuiBox-root, & .stack': {
                            height: '100%',
                        },
                    }}
                    style={{
                        zIndex: ZIndex.BottomSheet,
                    }}
                >
                    <IrdbDrawer>
                        <Available
                            imageOperation={imageOperation}
                            onCropper={() => {
                                setImageOperation(imageOperation);
                                setShowCropper(true);
                            }}
                            onAdd={onAdd}
                            onCancel={() => {
                                setImageOperation(undefined);
                            }}
                        />
                    </IrdbDrawer>
                </SwipeableDrawer>

                {/* Cropper */}
                <Drawer
                    anchor="bottom"
                    open={showCropper}
                    sx={{
                        zIndex: ZIndex.BottomSheet,
                    }}
                >
                    <Cropper
                        imageOperation={imageOperation}
                        onProgress={onCropperProgress}
                        onSuccess={async (i: ImageOperation<any>) => {
                            setShowCropper(false);
                            onCropperProgress(i);
                        }}
                        onRetake={() => {
                            setImageOperation(undefined);
                        }}
                        onCancel={() => {
                            setShowCropper(false);
                        }}
                        handleViewExisting={async imageOperation => {
                            setImageOperation(imageOperation);
                            setShowCropper(false);
                        }}
                    />
                </Drawer>

                {/* Info */}
                <SwipeableDrawer
                    anchor="bottom"
                    open={showInfo}
                    disableSwipeToOpen={true}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    onClose={function (event: SyntheticEvent<{}, Event>): void {
                        setImageOperation(undefined);
                    }}
                    onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                    style={{
                        zIndex: ZIndex.BottomSheet,
                    }}
                >
                    <IrdbDrawer>
                        <StackNav
                            initialComponent={(pushToStack, popFromStack) => {
                                return (
                                    <Info
                                        pushToStack={pushToStack}
                                        popFromStack={popFromStack}
                                        image={
                                            imageCreating || (imageOperation && imageFromImageOperation(imageOperation))
                                        }
                                        similarImages={
                                            imageOperation && similarImagesFromImageOperation(imageOperation)
                                        }
                                        isAdd={!!imageCreating}
                                        handleAdd={async () => {
                                            if (!imageOperation) return null;
                                            try {
                                                const { promise: uPromise } = await upload(
                                                    imageOperation,
                                                    _progress => {},
                                                );
                                                const uploaded = await uPromise;
                                                const { promise: aPromise } = await add(uploaded, 'publish');
                                                const added = await aPromise;
                                                if (
                                                    added.operation.Results?.ImageAdded &&
                                                    added.operation.Results.Image
                                                ) {
                                                    return added.operation.Results.Image.imageID;
                                                }
                                            } catch (error) {
                                                console.error(error);
                                            }
                                            return null;
                                        }}
                                        onSaved={async (id: string) => {
                                            const image = await load(id);
                                            setImageOperation(await imageOperationFromImage(image));
                                            setImageCreating(undefined);
                                            setSelectedImageId(id);
                                            setProfileOpen(true);
                                        }}
                                        onClose={() => {
                                            setImageOperation(undefined);
                                            setImageCreating(undefined);
                                        }}
                                    />
                                );
                            }}
                        />
                    </IrdbDrawer>
                </SwipeableDrawer>

                {/* Debug */}
                {debugEnabled && (
                    <SwipeableDrawer
                        anchor="bottom"
                        open={!!debug}
                        onClose={() => {}}
                        onOpen={() => {}}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: '100%',
                                height: '100%',
                                overflow: 'scroll',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'transparent',
                            },
                        }}
                        style={{
                            // zIndex doesn't work in sx
                            // zIndex: ZIndex.FAQ,
                            zIndex: 999999999,
                        }}
                    >
                        <Debug debug={debug} />
                    </SwipeableDrawer>
                )}

                {/* Iframe for Scan-on-Site */}
                {!!iframeURL && (
                    <SwipeableDrawer
                        anchor="bottom"
                        open={!!iframeURL}
                        onClose={() => {
                            setImageOperation(undefined);
                            setImageCreating(undefined);
                            setIframeURL('');
                        }}
                        onOpen={() => {}}
                        style={{
                            zIndex: ZIndex.FAQ,
                        }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: '100%',
                                height: '100%',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    setImageOperation(undefined);
                                    setImageCreating(undefined);
                                    setIframeURL('');
                                }}
                            >
                                <i
                                    className="fa-solid fa-xmark"
                                    style={{
                                        color: theme.palette.primary.main,
                                    }}
                                ></i>
                            </IconButton>
                        </Box>
                        <iframe
                            style={{
                                border: 0,
                                height: '100%',
                                width: '100%',
                            }}
                            src={iframeURL}
                        />
                    </SwipeableDrawer>
                )}

                {errorMessage && (
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            position: 'absolute',
                            top: 32,
                            zIndex: ZIndex.EphemeralMessage,
                            py: 1,
                            px: 2,
                            borderRadius: 2,
                            backgroundColor: 'rgba(0, 0, 0, .7)',
                            color: Color.White,
                            alignItems: 'center',
                        }}
                    >
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                            }}
                        >
                            {errorMessage}
                        </Typography>
                    </Stack>
                )}

                {closeMatchImage?.imageUrl && (
                    <Stack
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, .7)',
                            borderRadius: 2,
                            color: Color.White,
                            position: 'absolute',
                            left: '50%',
                            top: 90,
                            transform: 'translateX(-50%)',
                            width: 232,
                            textAlign: 'center',
                            p: 1,
                            px: 2,
                            zIndex: ZIndex.EphemeralMessage,
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                            }}
                        >
                            Possible match located:
                        </Typography>
                        <img
                            src={closeMatchImage.imageUrl}
                            alt=""
                            style={{ width: '100%', margin: '.5em 0' }}
                            onClick={async () => {
                                const operation = await imageOperationFromImage(closeMatchImage, Type.Query);
                                operation.operation.Results.ImageAlreadyExists = true;
                                setCloseMatchImage(undefined);
                                setErrorMessage(undefined);
                                setImageOperationWithScanToSite(operation);
                            }}
                        />
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={() => {
                                setCloseMatchImage(undefined);
                                setErrorMessage(undefined);
                            }}
                        >
                            <IconButton
                                sx={{
                                    p: 0,
                                }}
                            >
                                <i
                                    className="fa-solid fa-xmark"
                                    style={{
                                        color: Color.White,
                                    }}
                                />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                }}
                            >
                                DISMISS
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Box>
        );
    } else {
        // TODO: Currently not showing web app on desktop
        return (
            <Stack
                direction="column"
                spacing={8}
                sx={{
                    display: 'flex',
                    height: '100vh',
                    p: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#811425',
                }}
                style={{
                    height: '100dvh',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        objectFit: 'contain',
                        width: 'auto',
                        height: '56px',
                    }}
                    src={WordMarkWhite}
                    alt=""
                />
                <Typography
                    variant="mainFont6"
                    sx={{
                        textAlign: 'center',
                        color: Color.White,
                    }}
                >
                    Please wait while we redirect you to the IRCODE dashboard.
                </Typography>
            </Stack>
        );
    }
});

export default WebApp;
