import { useCallback, useContext } from 'react';
import useApi, { Method } from 'src/hooks/useApi';
import { Image } from 'src/types/Image';
import { Event } from 'src/types/Analytics';
import { ILink } from 'src/types/Link';
import { QueryResult } from './useAnalytics';
import UserContext, { TUser } from 'src/contexts/UserContext';

export interface Totals {
    totalRecent: number;
    totalUnique: number;
    totalOlder: number;
    totalOlderUnique: number;
    percentTotal: number;
    percentUnique: number;
}

export enum Parameter {
    CampaignId = 'campaignID',
    // Category = 'category',
    ImageId = 'imageID',
    // IsOwner = 'is_owner',
    // ImageTitle = 'image_title',
    ImageUrl = 'imageUrl',
    // Latitude = 'latitude',
    LinkTitle = 'title',
    LinkUrl = 'linkToFollow',
    // Longitude = 'longitude',
    // Match = 'match',
    // OwnerUserID = 'owner_user_id',
    // OwnerUsername = 'owner_username',
    // ViewerUserID = 'viewer_user_id',
    // ViewerUsername = 'viewer_username',
    // TrackingID = 'tracking_id',
    // ViewerUserProfileURL = 'viewer_user_profile_url',
    UserId = 'userID',
}

export enum Stat {
    GeoScans = 'geoScans',
    GeoViews = 'geoViews',
    ImageLinkOpened = 'imageLinkOpened',
    Scans = 'scanStats',
    ServerHits = 'serverHits',
    Views = 'viewStats',
}

// const irdbStatFromMetric = (metric: Metric): string => {
//     switch (metric) {
//         case Metric.Views:
//             return Stat.Views;
//         case Metric.Scans:
//             return Stat.Scans;
//         case Metric.ImageLinkOpened:
//             return Stat.ImageLinkOpened;
//         case Metric.GeoScans:
//             return Stat.GeoScans;
//         case Metric.GeoViews:
//             return Stat.GeoViews;
//         default:
//             throw new Error('Invalid metric');
//     }
// };

const irdbEventFromEvent = (event: Event): string => {
    switch (event) {
        case Event.ImageScanned:
            return 'imageScanned';
        case Event.ImageViewed:
            return 'imageViewed';
        case Event.ImageLinkOpened:
            return 'imageLinkOpened';
        // case Metric.Scans:
        //     return Stat.Scans;
        default:
            throw new Error('Invalid event');
    }
};

export interface TAnalytics {
    imageScanned: (image: Image) => void;
    imageViewed: (image: Image) => void;
    imageLinkOpened: (image: Image, link: ILink) => void;
    fetch: <T>(params: {
        campaignID: number;
        // metric: Metric;
        stat: Stat;
        timeframe?: number;
        interval?: string;
        intervalHits?: number;
        start?: string;
        end?: string;
        aggregated?: boolean;
        totals?: boolean;
    }) => Promise<QueryResult<T>[]>;
    fetchTotals: (params: {
        campaignID: number;
        stat: Stat;
        // metric: Metric;
        timeframe?: number;
        interval?: string;
        start?: string;
        end?: string;
        aggregated?: boolean;
        totals?: boolean;
    }) => Promise<Totals>;
}

const useAnalytics = (): TAnalytics => {
    const { request } = useApi();

    const { user } = useContext(UserContext) as TUser;

    // U should only call POST /Statistics on
    // imageLinkOpened
    // imageScanned ONLY if you do scan it in live view
    // linkedOpened
    // productButtonPressed
    // userProfileViewed
    // If they view an IRCODE  i.e. they pick one off the similar list or from profile view
    // then you call POST /Images/view/:imageID

    const trackEvent = useCallback(async (event: Event, properties: Record<Parameter, any>) => {
        console.log('trackEvent event', event, properties);

        // curl -i -X POST \
        // https://us-central1-ircode-1a662.cloudfunctions.net/development-v1_39/Statistics \
        // -H "Content-Type: application/json" \
        // -d '{
        //   "eventType": "imageLinkOpened",
        //   "imageID": "<imageID>",
        //   "imageUrl": "<product imageUrl>",
        //   "linkToFollow": "<product link>",
        //   "title": "<product title>"
        // }'
        // const {campaignID = 0, count = 0, eventType, failed, imageID, imageUrl, linkToFollow, title, userID} = req.body;

        const response = await request({
            method: Method.POST,
            path: '/Statistics',
            data: {
                eventType: irdbEventFromEvent(event),
                ...properties,
                // campaignid: campaignID,
                // stat,
                // timeframe,
                // interval,
                // start: timeframe ? undefined : start,
                // end:
                //     timeframe ? undefined
                //     : end ? end
                //     : start,
                // aggregated,
                // totals,
            },
        });
        // analyticsWrite
        //     .track(event, {
        //         // TODO: See AnalyticsEvent.swift
        //         // "image_id": imageModel.imageID,
        //         // "is_owner": imageModel.imageUser.isMe,
        //         // "image_title": imageModel.title ?? "",
        //         // "image_url": imageModel.imageUrl ?? "",
        //         // "viewer_user_id": UserData.shared.me.userID,
        //         // "viewer_username": UserData.shared.me.userName,
        //         // "owner_user_id": imageModel.imageUser.userID,
        //         // "owner_username": imageModel.imageUser.userName,
        //         // "tracking_id": UserData.shared.trackingId ?? "",
        //         // "viewer_user_profile_url": UserData.shared.me.profileUrl,
        //         // "latitude": UserData.shared.currentLocation.coordinate.latitude,
        //         // "longitude": UserData.shared.currentLocation.coordinate.longitude,
        //         ...properties,
        //     })
        //     .then(res => {
        //         console.log('res', res);
        //     });
    }, []);

    // curl -i -X POST \
    // https://us-central1-ircode-1a662.cloudfunctions.net/development-v1_39/Statistics \
    // -H "Content-Type: application/json" \
    // -d '{
    //   "eventType": "imageLinkOpened",
    //   "imageID": "<imageID>",
    //   "imageUrl": "<product imageUrl>",
    //   "linkToFollow": "<product link>",
    //   "title": "<product title>"
    // }'
    // const {campaignID = 0, count = 0, eventType, failed, imageID, imageUrl, linkToFollow, title, userID} = req.body;

    const imageScanned = useCallback(
        async (image: Image) => {
            console.log('[ANALYTICS IRDB] imageScanned', image);

            // TODO: Centralize in trackEvent
            // await request({
            //     method: Method.POST,
            //     path: `/Images/view/${image.imageID}`,
            // });

            // trackEvent(Event.ImageScanned, {
            //     [Parameter.CampaignID]: image.campaignID,
            //     [Parameter.ImageID]: image.imageID,
            //     // [Parameter.ImageTitle]: metaContentForMetaType(image, MetaType.Title)?.title ?? '',
            //     [Parameter.ImageURL]: image.imageUrl,
            // } as Record<Parameter, any>);

            trackEvent(Event.ImageScanned, {
                [Parameter.CampaignId]: image.campaignID,
                [Parameter.ImageId]: image.imageID,
                // [Parameter.ImageTitle]: metaContentForMetaType(image, MetaType.Title)?.title ?? '',
                [Parameter.ImageUrl]: image.imageUrl,
                // [Parameter.LinkURL]: link.url,
                // [Parameter.LinkTitle]: link.title,
                // [Parameter.LinkTitle]: link.title,
                // [Parameter.LinkURL]: link.url,
                [Parameter.UserId]: user?.userID,
            } as Record<Parameter, any>);
        },
        [trackEvent],
    );

    const imageViewed = useCallback(
        async (image: Image) => {
            console.log('[ANALYTICS IRDB] imageViewed', image);

            // await request({
            //     method: Method.POST,
            //     path: `/Images/view/${image.imageID}`,
            // });

            trackEvent(Event.ImageViewed, {
                [Parameter.CampaignId]: image.campaignID,
                [Parameter.ImageId]: image.imageID,
                // [Parameter.ImageTitle]: metaContentForMetaType(image, MetaType.Title)?.title ?? '',
                [Parameter.ImageUrl]: image.imageUrl,
                [Parameter.UserId]: user?.userID,
            } as Record<Parameter, any>);
        },
        [request, trackEvent],
    );

    const imageLinkOpened = useCallback(
        (image: Image, link: ILink) => {
            trackEvent(Event.ImageLinkOpened, {
                [Parameter.CampaignId]: image.campaignID,
                [Parameter.ImageId]: image.imageID,
                // [Parameter.ImageTitle]: metaContentForMetaType(image, MetaType.Title)?.title ?? '',
                [Parameter.ImageUrl]: image.imageUrl,
                [Parameter.LinkUrl]: link.url,
                [Parameter.LinkTitle]: link.title,
                // [Parameter.LinkTitle]: link.title,
                // [Parameter.LinkURL]: link.url,
            } as Record<Parameter, any>);
            // case .imageLinkOpened(let imageModel, let linkModel):
            //     return [
            //         "image_id": imageModel.imageID,
            //         "image_title": imageModel.title ?? "",
            //         "image_url": imageModel.imageUrl ?? "",
            //         "viewer_user_id": UserData.shared.me.userID,
            //         "viewer_username": UserData.shared.me.userName,
            //         "owner_user_id": imageModel.imageUser.userID,
            //         "owner_username": imageModel.imageUser.userName,
            //         "viewer_user_profile_url": UserData.shared.me.profileUrl,
            //         "latitude": UserData.shared.currentLocation.coordinate.latitude,
            //         "longitude": UserData.shared.currentLocation.coordinate.longitude,
            //         "link_id": linkModel.id,
            //         "link_title": linkModel.title,
            //         "link_url": linkModel.urlString,
            //         "link_on_touch_display": linkModel.onTouchDisplay,
            //         "link_on_scan_display": linkModel.onScanDisplay,
            //         "tracking_id": UserData.shared.trackingId ?? "",
            //     ]
        },
        [trackEvent],
    );

    const fetch = useCallback(
        async <T>({
            campaignID,
            stat,
            timeframe,
            interval,
            intervalHits,
            start,
            end,
            aggregated = false,
            totals = false,
            // metric,
        }: {
            campaignID: number;
            stat: Stat;
            timeframe?: number;
            interval?: string;
            intervalHits?: number;
            start?: string;
            end?: string;
            aggregated?: boolean;
            totals?: boolean;
            // metric: Metric;
        }): Promise<QueryResult<T>[]> => {
            console.log(
                '[ANALYTICS IRDB] fetch',
                campaignID,
                stat,
                timeframe,
                interval,
                intervalHits,
                start,
                end,
                aggregated,
                totals,
            );
            // const stat = irdbStatFromMetric(metric);
            const response = await request({
                method: Method.GET,
                path: '/Statistics/fetch',
                data: {
                    campaignid: campaignID,
                    stat,
                    // stat: irdbStatFromMetric(metric),
                    timeframe,
                    interval,
                    intervalHits,
                    start: timeframe ? undefined : start,
                    end:
                        timeframe ? undefined
                        : end ? end
                        : start,

                    aggregated,
                    totals,
                },
            });

            if (response.data.Results.error) {
                throw new Error(response.data.Results.error);
            }

            switch (stat) {
                case Stat.GeoScans:
                case Stat.GeoViews:
                case Stat.Scans:
                case Stat.Views:
                    return response.data.Results[stat];
                case Stat.ServerHits: {
                    // Convert for my own sanity
                    const serverHits = response.data.Results.serverHits;
                    return serverHits.map((serverHit: any) => {
                        return {
                            timeframe: serverHit.intervalStart,
                            value: {
                                imageScanned: serverHit.imageScanned,
                                imageLinkOpened: serverHit.imageLinkOpened,
                                users: serverHit.users,
                            },
                            // {
                            //     imageLinkOpened: serverHit.imageLinkOpened,
                            // },
                            // {
                            //     users: serverHit.users,
                            // },
                        };
                    });
                    // return {
                    //     // data: response.data.Results[stat],
                    //     // total: response.data.Results.total,
                    // }

                    // return '';
                    // return response.data.Results[stat];
                    // break;
                }
                case Stat.ImageLinkOpened:
                    return response.data.Results.links;
                default:
                    throw new Error('Invalid stat');
            }
        },
        [request],
    );

    const fetchTotals = useCallback(
        async <T>({
            campaignID,
            stat,
            timeframe,
            interval,
            start,
            end,
            aggregated = false,
            totals = false,
            // metric,
        }: {
            campaignID: number;
            stat: Stat;
            timeframe?: number;
            interval?: string;
            start?: string;
            end?: string;
            aggregated?: boolean;
            totals?: boolean;
            // metric: Metric;
        }): Promise<Totals> => {
            const response = await request({
                method: Method.GET,
                path: '/Statistics/fetch',
                data: {
                    campaignid: campaignID,
                    // stat: irdbStatFromMetric(metric),
                    stat,
                    timeframe,
                    interval,
                    start: timeframe ? undefined : start,
                    end:
                        timeframe ? undefined
                        : end ? end
                        : start,
                    aggregated,
                    totals,
                },
            });
            return response.data.Results;
        },
        [request],
    );

    return {
        imageScanned,
        imageViewed,
        imageLinkOpened,
        fetch,
        fetchTotals,
    };
};

export default useAnalytics;
